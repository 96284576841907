import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import dashIcon from '../../static/navigation/Dashicon.svg';
// Custom component React

// Material UI Component
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Images and logo
import DashboardIcon from '@mui/icons-material/Dashboard';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Dashboard from '@mui/icons-material/Dashboard';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import { useMediaQuery } from '@mui/material';

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&$selected': {
            color: '#fff', // Active tab text color
          },
          '&:not($selected)': {
            color: 'orange', // Non-active tab text color
          },
        },
      },
    },
  },
});

const navList = [
  { label: "Dashboard", icon: <CropSquareIcon sx={{ color: '#fff' }} />, to: "/dashboard" },
  { label: "Add Event", icon: <EventAvailableIcon />, to: "/add-event" },
  { label: "Reminders", icon: <PhoneIphoneIcon />, to: "/reminders" },
  { label: "Contacts", icon: <PermContactCalendarIcon />, to: "/contacts" },
  { label: "Billing", icon: <AccountBalanceIcon />, to: "/billing" }
]
const activeColor = "#FF0045";

const HorizontalNav = ({ activeTab, setActiveTab }) => {

  const isSmallScreen = useMediaQuery('(max-width:1200px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  //   State variable
  const location = useLocation();
  const value = activeTab;

  React.useEffect(() => {
    const currentPath = location.pathname;
    const tabIndex = navList.findIndex(item => item.to === currentPath);

    if (tabIndex !== -1) {
      setActiveTab(tabIndex);
    }
  }, [location, setActiveTab]);

  //   Handle Functions
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: 'relative' }}>
        <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example" variant={ isTablet ? "scrollable" : "auto" } scrollButtons="auto">
          {navList.map((item, index) => (
            <Tab
              key={index}
              icon={
                index === 0 
                ? (
                <>
                  <CropSquareIcon sx={{ color: '#fff' }} />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    style={{
                      position: 'absolute',
                      top: 25,
                      left: 10,
                      zIndex: 1,
                    }}
                  >
                    <image
                      href={dashIcon}
                      width="100%"
                      height="100%"
                      filter={value === 0 ? 'none' : 'url(#grayscale)'} // Apply grayscale filter when not active
                    />
                    {/* Define the grayscale filter */}
                    <filter id="grayscale">
                      <feColorMatrix
                        type="matrix"
                        values="0.2222 0.2222 0.2222 0 0
                                0.2222 0.2222 0.2222 0 0
                                0.2222 0.2222 0.2222 0 0
                                0      0      0      1 0"
                      />
                    </filter>
                  </svg> 
                </>
                ) : 
                  React.cloneElement(item.icon, { style: { color: index === value ? activeColor : 'inherit' } })}
              label={item.label}
              component={Link}
              to={item.to}
              iconPosition='start'
              sx={{
                '&.Mui-selected': {
                  color: '#707070', // Active label color
                  opacity: 1,
                  textTransform: 'none',
                  fontSize: '17px',
                  fontFamily: 'Filson Pro Regular'
                },
                '&:not(.Mui-selected)': {
                  color: '#898888', // Non-active label color
                  textTransform: 'none',
                  fontSize: '17px',
                  fontFamily: 'Filson Pro Light'
                },
                marginRight: isSmallScreen ? "0rem" : "5rem",
              }}
            />
          ))}
        </Tabs>
      </div>
    </ThemeProvider>
  )
}

export default HorizontalNav;
