import React from 'react'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import './Datetime.css'

const DatePick = (props) => {
  const initialDate = dayjs().add(1, 'day');
  const [value, setValue] = React.useState(initialDate);

  const width = props.width ? props.width : '325px'

  const handleDate = (newValue) => {
    setValue(newValue)
    props.date(newValue)
  }

  const customStyles = {
    background: 'white', // Set the background color to white
    border: '1px solid',
    borderColor: '#9d9d9d1f',
    borderRadius: "6px",
    width: `${width}`,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          label={
            <span className='labelFont'>Select Event Date & Time</span>
          }
          value={value}
          onChange={(newValue) => handleDate(newValue)}
          minDateTime={dayjs()}
          slotProps={{ 
            textField: { 
                size: 'small',
            }
          }}
          sx={{
              '& .MuiInputBase-root': customStyles, // Apply custom styles to the input base
          }}
          
        />
      </DemoContainer>
    </ LocalizationProvider>
  )
}

export default DatePick