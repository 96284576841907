import React from 'react'
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InputBootstrapMulti from '../inputfields/InputBootstrapMulti';
import ButtonGradient from '../button/ButtonGradient';
import SendIcon from '@mui/icons-material/Send';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import APP_CONFIG from '../../Const';


const drawerWidth = 400;

const SupportDrawers = (props) => {

  const [text, setText] = React.useState('');

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }
    props.setState(open);
  };

  const handleSupport = () => {
    const item = {
        name: Cookies.get('name'),
        email: Cookies.get('email'),
        subject: "subject",
        message: text
    }

    const config = {
        headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            Accept: 'application/json'
        }
    };

    axios.post("https://api.wave.tickets/api/submit-ticket", item, config)
        .then((response) => {
            toast(response.data.message, { type: ["success"] })
            props.setState(false)
        })
        .catch((error) => {
            toast("Support message delivery error", { type: ["error"] })
        })
  }

  const list = () => (

    <Box
        sx={{ width: drawerWidth }}
        role="presentation"
    >

        <Box sx={{
            background: '#F7F7F7',
            height: '50px',
            px: 3,
            py: 3,
            position: 'sticky',
            zIndex: 1,
            top: 0,

        }}>
        
        <Grid container >
            <Grid item xs={2} sx={{ mt: 0.5 }}>
                <Avatar sx={{ bgcolor: '#FF0065' }}>
                    <ContactSupportIcon sx={{ height: 30, width: 30 }} />
                </Avatar>
            </Grid>
            <Grid item xs={9}>
                <Stack direction="column">
                    <Typography variant="body1" sx={{ fontFamily: 'Filson Pro Bold' }}>Support</Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Filson Pro Light' }}>Contact: support@wave.tickets</Typography>
                </Stack>
            </Grid>
        </Grid>

        </Box>

        <Box sx={{
        overflowY: 'scroll',
        mt: 2,
        px: 3,
        flex: 1,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        }}>

            <Stack direction="column" spacing={2}>

                <Typography sx={{ fontFamily: 'Filson Pro Medium', fontSize: '22px', textAlign: 'center', color: "#7500FF" }}>Submit Your Support Request</Typography>
                <Typography sx={{ fontFamily: 'Filson Pro Light', fontSize: '16px', textAlign: 'center' }}>Our support team will contact you soon</Typography>
                <Box sx={{
                    ml: "6px !important"
                }}>
                    <InputBootstrapMulti label="Message..." dataType="String" text={setText} />
                </Box>
                
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <ButtonGradient text="Send" icon={<SendIcon />} handleSave={handleSupport} />
                </Box>
            </Stack>

        </Box>

    </Box>

  );

  return (
    <Drawer
        anchor='right'
        open={props.state}
        onClose={toggleDrawer(false)}
        // onClick={toggleDrawer(false)}
        sx={{
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth, 
            borderRadius: '31px 0 0 31px', 
            background: "#FFFFFF",
            boxShadow: '-10px 0px 6px #00000029',
            border: '1px solid #707070',
            },
        }}
    >
        {list()}
    </Drawer>
  )
}

export default SupportDrawers