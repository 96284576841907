import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/payment/PaymentForm'
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie'

const PUBLIC_KEY = "pk_live_51NpxiUApfcNZm9v8hYI0JYznLWIqs5QlqXdOalLK6NvM2k1keREpPyLYCSkcvF5ay5Y6NXen2EB9J2DGVAy5KKyR00wMDzXpO2"
const stripeTestPromise = loadStripe(PUBLIC_KEY)

const StripePayment = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");

  React.useEffect(() => {
      if (!token) {
        navigate('/')
      }
  }, [])
  return (
    <Elements stripe={stripeTestPromise}>
        <PaymentForm />
    </Elements>
  )
}

export default StripePayment