import React, { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import axios from 'axios';

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#c4f0ff",
			color: "#fff",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#87bbfd" }
		},
		invalid: {
			iconColor: "#ffc7ee",
			color: "#ffc7ee"
		}
	}
}

const PaymentForm = () => {

  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async(e) => {
    
    e.preventDefault();
    const [error, paymentMethod] = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement)
    })

    if (!error) {
        try {
            const {id} = paymentMethod
            const response = axios.post("", {
                amount: 1000,
                id
            })

            if (response.data.success) {
                console.log("Successfull payment transaction")
                setSuccess(true)
            }

        } catch (error) {
            console.log("Error", error)
        }
    } else {
        console.log(error.message)
    }

    
  }

  return (
    <>
    {!success 
        ?
        <form onSubmit={handleSubmit}>
            <fieldset className='FormGroup'>
                <div className='FormRow'>
                    <CardElement options={CARD_OPTIONS} />
                </div>
            </fieldset>
            <button>pay</button>
        </form>
        :
        <div>
            <h2>You have paid your payment for this "name of event" event</h2>
        </div>
    }
    </>
  )
}

export default PaymentForm