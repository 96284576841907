import * as React from "react";
import Cookies from "js-cookie";
import axios from "axios";

// Material UI Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

// Material UI component
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Stack, Box, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns/format";

const StyledRow = styled(TableRow)(() => ({
  "&:hover": {
    backgroundColor: "#F7F3FF",

    // Target the TableCell when the TableRow is hovered
    "& .MuiTableCell-root": {
      backgroundColor: "#F7F3FF",
    },
  },
}));

const StyledCell = styled(TableCell)(() => ({
  // Ensuring that by default there's no background or it matches your table's default background
  backgroundColor: "transparent",
}));

const name = {
  id: "name",
  label: "Name",
  minWidth: 17,
  align: "left",
};

const datepaid = {
  id: "datepaid",
  label: "Date Paid",
  minWidth: 17,
  align: "left",
};

const fundsSet = {
  id: "fundsSet",
  label: "Funds Settled",
  minWidth: 20,
  align: "left",
};

const amount = {
  id: "amount",
  label: "Amount",
  minWidth: 20,
  align: "left",
};

const wave = {
  id: "wave",
  label: "Wave Fee",
  minWidth: 20,
  align: "left",
};

export const currencyFormatter = (price, currency) => {
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);
};

const BillRecieve = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const isMedium = useMediaQuery("(max-width:1000px)");
  const isSmall = useMediaQuery("(max-width:800px)");
  const [billings, setBillings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loadingMoreData, setLoadingMoreData] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);

  const fontSize = !isMedium ? "15px" : isSmall ? "10px" : "12px";

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (
      scrollHeight - scrollTop <= clientHeight + 5 &&
      hasMore &&
      !loadingMoreData
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const getBillings = async (config) => {
    try {
      setBillings([]);
      if (page === 0) {
        setIsLoading(true);
      } else {
        setLoadingMoreData(true);
      }
      const { data } = await axios.get(
        `https://api.wave.tickets/api/billing`,
        config
      );

      // setBillings((prevData) => [...prevData, ...data.billing.data]);
      setBillings(data.billing.data);
      if (data.billing.data.length < rowsPerPage) {
        setHasMore(false);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
      setLoadingMoreData(false);
    }
  };

  React.useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        Accept: "application/json",
      },
      params: {
        page: page + 1,
        search: props.query,
        category: props.selectedCategory,
      },
    };
    getBillings(config);
  }, [page, props.selectedCategory, props.query]);

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          overflowX: "scroll",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: 360,
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
          }}
          onScroll={handleScroll}
        >
          <Table stickyHeader aria-label="sticky table" sx={{ width: "98%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  key={name.id}
                  align={name.align}
                  style={{
                    minWidth: name.minWidth,
                    backgroundColor: "#F3F1F6",
                  }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {name.label}
                </TableCell>
                <TableCell
                  key={datepaid.id}
                  align={datepaid.align}
                  style={{
                    minWidth: datepaid.minWidth,
                    backgroundColor: "#F3F1F6",
                  }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {datepaid.label}
                </TableCell>
                <TableCell
                  key={fundsSet.id}
                  align={fundsSet.align}
                  style={{
                    minWidth: fundsSet.minWidth,
                    backgroundColor: "#F3F1F6",
                  }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {fundsSet.label}
                </TableCell>
                <TableCell
                  key={amount.id}
                  align={amount.align}
                  style={{
                    minWidth: amount.minWidth,
                    backgroundColor: "#F3F1F6",
                  }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {amount.label}
                </TableCell>
                <TableCell
                  key={wave.id}
                  align={wave.align}
                  style={{
                    minWidth: wave.minWidth,
                    backgroundColor: "#F3F1F6",
                  }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {wave.label}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {billings
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .flatMap((billing, index) => {
                  console.log(billing, index);
                  const date_paid = format(
                    billing.charge_data.created * 1000,
                    "dd/MM/yy"
                  );
                  return (
                    <React.Fragment key={index}>
                      <StyledRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={"styled-" + index}
                      >
                        {/* Styled cell */}
                        <StyledCell
                          align="left"
                          sx={{
                            borderRadius: "32px 0 0 32px",
                            border: "1px solid #C7C7C7",
                            borderRight: "none",
                            fontFamily: "Filson Pro Regular",
                            fontSize: fontSize,
                          }}
                        >
                          {billing.event.status === "complete" ? (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Stack direction="row" spacing={2}>
                                  <CheckCircleIcon
                                    sx={{
                                      height: 20,
                                      width: 20,
                                      color: "#7500FF",
                                      mt: "-2px !important",
                                    }}
                                  />
                                  <span>{billing.event.name}</span>
                                </Stack>
                              </Box>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <Stack direction="row" spacing={2}>
                                  <CancelOutlinedIcon
                                    sx={{
                                      height: 20,
                                      width: 20,
                                      color: "#FF0065",
                                      mt: "-2px !important",
                                    }}
                                  />
                                  <span>{billing.event.name}</span>
                                </Stack>
                              </Box>
                            </>
                          )}
                        </StyledCell>

                        <StyledCell
                          align="left"
                          sx={{
                            border: "1px solid #C7C7C7",
                            borderRight: "none",
                            borderLeft: "none",
                            fontFamily: "Filson Pro Regular",
                            fontSize: fontSize,
                          }}
                        >
                          {date_paid}
                        </StyledCell>
                        <StyledCell
                          align="left"
                          sx={{
                            border: "1px solid #C7C7C7",
                            borderRight: "none",
                            borderLeft: "none",
                            fontFamily: "Filson Pro Regular",
                            fontSize: fontSize,
                          }}
                        ></StyledCell>
                        <StyledCell
                          align="left"
                          sx={{
                            border: "1px solid #C7C7C7",
                            borderRight: "none",
                            borderLeft: "none",
                            color: "#7500FF",
                            fontFamily: "Filson Pro Bold",
                            fontSize: fontSize,
                          }}
                        >
                          {currencyFormatter(
                            billing.charge_data.amount_total/100,
                            billing.charge_data.currency
                          )}
                        </StyledCell>
                        <StyledCell
                          align="left"
                          sx={{
                            border: "1px solid #C7C7C7",
                            borderRadius: "0 32px 32px 0",
                            borderRight: "none",
                            borderLeft: "none",
                            fontFamily: "Filson Pro REgular",
                            fontSize: fontSize,
                          }}
                        >
                          {" "}
                          {currencyFormatter(
                            billing.quantity * 1,
                            billing.charge_data.currency
                          )}
                        </StyledCell>
                      </StyledRow>
                      <TableRow
                        style={{ height: "5px" }}
                        key={"space-" + index}
                      ></TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {loadingMoreData && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 5,
            mt: 2,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default BillRecieve;
