import React from 'react';

// Custom component React

// Material UI Component
import { Button } from '@mui/material';

// Images and logo


const ElongatedButtonTranparent = (props) => {
  return (
    <Button 
        variant="outlined"
        // component={props.link}
        // to={props.to}
        onClick={props.onClickAction}
        sx={{ 
            color: '#fff',
            height: '52px',
            width: '172px',
            border: '2px solid #FFFFFF',
            borderRadius: "32px",
            textTransform: 'none',
            fontSize: '20px',
            fontFamily: props.fontFam,
            "&:hover": {
                color: '#fff',
                border: '2px solid #FFFFFF',
                borderRadius: "32px",
            }
        }}
    >
        {props.label}
    </Button>
  )
}

export default ElongatedButtonTranparent