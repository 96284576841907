import { Typography, Box, Button } from '@mui/material'
import React from 'react'

const AnimationBill = () => {
  return (
    <Box sx={{
        width: '100%',
        height: '80vh',
        background: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        mt: -8
    }}>


        <Typography sx={{
            fontFamily: "Alverata Bold Italic",
            fontSize: '25px',
        }}>
            Let's get you connected!
        </Typography>

        <Box sx={{ 
            mt: 3.5, 
        }} 
        />

        <Button sx={{ 
            color: '#fff',
            height: 'auto',
            width: 'auto',
            px: 2,
            background: '#4D00FF',
            opacity: 1,
            borderRadius: "20px",
            textTransform: 'none',
            fontSize: '20px',
            fontFamily: 'Filson Pro Medium',
            "&:hover": {
                color: '#fff',
                background: '#4D00FF',
                borderRadius: "20px",
            }
         }}
         variant="contained"
         >
            <Typography sx={{  fontFamily: 'Filson Pro Bold Italic', fontSize: 25  }}>Connect </Typography>
            <Typography sx={{ ml: 1.5, fontFamily: 'Filson Pro Heavy', fontSize: 35 }}>Stripe</Typography>
        </Button>

        <Box sx={{ mt: 1 }} />

        <Button sx={{ 
            color: '#000',
            height: '60px',
            width: '255px',
            px: 2,
            opacity: 1,
            borderRadius: "20px",
            textTransform: 'none',
            fontSize: '20px',
            border: '1px solid #707070',
            fontFamily: 'Filson Pro Medium',
            "&:hover": {
                color: '#000',
                borderRadius: "20px",
                border: '1px solid #707070'
            }
         }}
         variant="outlined"
         >
            <Typography sx={{  fontFamily: 'Filson Pro Bold Italic', fontSize: 25  }}>Create Account </Typography>
        </Button>

    </Box>
  )
}

export default AnimationBill