import * as React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from "react-toastify";

// Material UI Icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ChatRoundedIcon from "@mui/icons-material/ChatRounded";

// Material UI component
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Stack, Box, IconButton, useMediaQuery } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import APP_CONFIG from "../../Const";

const StyledRow = styled(TableRow)(() => ({
  "&:hover": {
    backgroundColor: "#E4D6FF",

    // Target the TableCell when the TableRow is hovered
    "& .MuiTableCell-root": {
      backgroundColor: "#E4D6FF",
    },
  },
}));

const StyledCell = styled(TableCell)(() => ({
  // Ensuring that by default there's no background or it matches your table's default background
  backgroundColor: "transparent",
}));

const name = {
  id: "name",
  label: "Name",
  minWidth: 17,
  align: "left",
};

const optin = {
  id: "optin",
  label: "Opt-In",
  minWidth: 17,
  align: "left",
};

const optout = {
  id: "optout",
  label: "Opt-Out",
  minWidth: 20,
  align: "left",
};

const purchase = {
  id: "purchase",
  label: "Purchases",
  minWidth: 20,
  align: "left",
};

const phone = {
  id: "phone",
  label: "Phone",
  minWidth: 17,
  align: "left",
};

const email = {
  id: "email",
  label: "Email",
  maxWidth: 10,
  align: "left",
};

function createData(
  name,
  optin,
  optout,
  purchase,
  phone,
  email,
  status,
  chatid
) {
  return { name, optin, optout, purchase, phone, email, status, chatid };
}

const ContactTable = (props) => {
  const isMediumScreen = useMediaQuery("(max-width:1100px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const fontSize = !isMediumScreen ? "15px" : isSmallScreen ? "9px" : "10px";
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = React.useState(true);
  const [dataDisplay, setDataDisplay] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [loadingMoreData, setLoadingMoreData] = React.useState(false);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (
      scrollHeight - scrollTop <= clientHeight + 5 &&
      hasMore &&
      !loadingMoreData
    ) {
      setLoadingMoreData(true);
      setTimeout(() => {
        setPage((prevPage) => prevPage + 1);
        setLoadingMoreData(false);
      }, 500);
    }
  };

  React.useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        Accept: "application/json",
      },
    };

    if (page === 0) {
      setIsLoading(true); // Show loader for initial data load
    } else {
      setLoadingMoreData(true); // Show loader for subsequent data loads
    }
    // name, optin, optout, purchase, phone, email, status, chatid
    axios
      .get(`https://api.wave.tickets/api/contacts?page=${page + 1}`, config)
      .then((response) => {
        const datamap = response.data.contacts.data.map((item) => {
          // console.log(item.id)
          let status = "YES";
          return createData(
            item.name,
            item.opt_in,
            item.opt_out,
            item.purchases,
            item.phone,
            item.email,
            status,
            item.id
          );
        });

        setDataDisplay((prevData) => [...prevData, ...datamap]);

        if (response.data.events.data.length < rowsPerPage) {
          setHasMore(false); // Indicate no more data to load
        }

        if (page === 0) {
          setIsLoading(false); // Hide loader for initial data load
        } else {
          setLoadingMoreData(false); // Hide loader for subsequent data loads
        }
      })
      .catch((err) => {
        // toast("Network Error - Bad Request", { type: ["error"] });

        if (page === 0) {
          setIsLoading(false);
        } else {
          setLoadingMoreData(false);
        }
      });
  }, [page]);

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          overflowX: "scroll",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: 360,
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
          }}
          onScroll={handleScroll}
        >
          <Table stickyHeader aria-label="sticky table" sx={{ width: "98%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  key={name.id}
                  align={name.align}
                  style={{ minWidth: name.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {name.label}
                </TableCell>
                <TableCell
                  key={optin.id}
                  align={optin.align}
                  style={{ minWidth: optin.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {optin.label}
                </TableCell>
                <TableCell
                  key={optout.id}
                  align={optout.align}
                  style={{ minWidth: optout.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {optout.label}
                </TableCell>
                <TableCell
                  key={purchase.id}
                  align={purchase.align}
                  style={{ minWidth: purchase.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {purchase.label}
                </TableCell>
                <TableCell
                  key={phone.id}
                  align={phone.align}
                  style={{ minWidth: phone.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {phone.label}
                </TableCell>
                <TableCell
                  key={email.id}
                  align={email.align}
                  style={{ maxWidth: email.maxWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {email.label}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataDisplay.map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    <StyledRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={"styled-" + index}
                    >
                      {/* <StyledCell align={name.align} sx={{ borderRadius: "32px 0 0 32px", border: "1px solid #C7C7C7", borderRight: "none", background: '#F7F3FF', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                            {
                                                row.status === "Yes" 
                                                ?
                                                    <>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                            <Stack direction="row" spacing={2}>
                                                                <CheckCircleIcon sx={{ height: 20, width: 20, color: '#7500FF', mt: '-2px !important' }} />
                                                                <span>
                                                                    {row.name}
                                                                </span>
                                                            </Stack>      
                                                        </Box>
                                                    </>
                                                :
                                                    row.status === "No"
                                                ?
                                                    <>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                    <Stack direction="row" spacing={2}>
                                                                        <CancelOutlinedIcon sx={{ height: 20, width: 20, color: '#FF0065', mt: '-2px !important' }} />
                                                                        <span>
                                                                            {row.name}
                                                                        </span>
                                                                    </Stack>
                                                        </Box>
                                                    </>
                                                :
                                                    console.log("Error in data")
                                            }
                                            </StyledCell> */}
                      <StyledCell
                        align={name.align}
                        sx={{
                          borderRadius: "32px 0 0 32px",
                          border: "1px solid #C7C7C7",
                          borderRight: "none",
                          background: "#F7F3FF",
                          fontFamily: "Filson Pro Regular",
                          fontSize: fontSize,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          {row.name}
                        </Box>
                      </StyledCell>
                      <StyledCell
                        align={optin.align}
                        sx={{
                          border: "1px solid #C7C7C7",
                          borderRight: "none",
                          borderLeft: "none",
                          background: "#F7F3FF",
                          fontFamily: "Filson Pro Light",
                          fontSize: fontSize,
                        }}
                      >
                        {row.optin}
                      </StyledCell>
                      <StyledCell
                        align={optout.align}
                        sx={{
                          border: "1px solid #C7C7C7",
                          borderRight: "none",
                          borderLeft: "none",
                          background: "#F7F3FF",
                          fontFamily: "Filson Pro Light",
                          fontSize: fontSize,
                        }}
                      >
                        {row.optout}
                      </StyledCell>
                      <StyledCell
                        align={purchase.align}
                        sx={{
                          border: "1px solid #C7C7C7",
                          borderRight: "none",
                          borderLeft: "none",
                          background: "#F7F3FF",
                          color: "#7500FF",
                          fontFamily: "Filson Pro Bold",
                          fontSize: fontSize,
                        }}
                      >
                        {row.purchase}
                      </StyledCell>
                      <StyledCell
                        align={phone.align}
                        sx={{
                          border: "1px solid #C7C7C7",
                          borderRight: "none",
                          borderLeft: "none",
                          background: "#F7F3FF",
                          fontFamily: "Filson Pro Light",
                          fontSize: fontSize,
                        }}
                      >
                        {row.phone}
                      </StyledCell>
                      <StyledCell
                        align={email.align}
                        sx={{
                          borderRadius: "0 32px 32px 0",
                          border: "1px solid #C7C7C7",
                          borderRight: "none",
                          borderLeft: "none",
                          background: "#F7F3FF",
                          fontFamily: "Filson Pro Light",
                          fontSize: fontSize,
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <span>{row.email}</span>
                          <IconButton
                            sx={{ mt: "1px !important", height: 15, width: 15 }}
                            onClick={props.toggler(
                              true,
                              row.name,
                              row.phone,
                              row.chatid
                            )}
                          >
                            <ChatRoundedIcon
                              sx={{ height: 20, width: 20, color: "#7500FF" }}
                            />
                          </IconButton>
                        </Stack>
                      </StyledCell>
                    </StyledRow>

                    <TableRow
                      style={{ height: "5px" }}
                      key={"space-" + index}
                    ></TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {loadingMoreData && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 5,
            mt: 2,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default ContactTable;
