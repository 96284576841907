import React from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie'
// Custom component React
import Navbar from '../components/Navigation/Navbar';

// Material UI Component
import { Box, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import HorizontalNav from '../components/Navigation/HorizontalNav';
import Category from '../components/filter/Category';
import Areachart from '../components/chart/Areachart';
import Searchbar from '../components/search/Searchbar';
import EventTable from '../components/tables/EventTable';

// Images and logo
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ButtonIcon from '../components/button/ButtonIcon';
// import DateFilter from '../components/filter/DateFilter';
import BillPaid from '../components/tables/BillPaid';
import AdminList from '../components/tables/AdminList';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AddUser from '../components/dialogs/AddUser';

const SuperAdmin = () => {
    const isSmallScreen = useMediaQuery('(max-width:950px)')
    const isTablet = useMediaQuery('(max-width:830px)')
    const isTabletMedium = useMediaQuery('(max-width:900px)')
    const position = isTabletMedium ?  'center' : 'flex-end';
    const [openDialog, setOpenDialog] = React.useState(false);
    const navigate = useNavigate();
    const token = Cookies.get("token");
    const adminAccess = Cookies.get("is_admin")

    React.useEffect(() => {
        if (!token) {
          navigate('/')
        }
    }, [])

    React.useEffect(() => { 
        if (!adminAccess) {
            navigate('/dashboard')
        }     
    }, [])
    

  return (
    <>
        <Navbar admin={"admin"} />

        <Box sx={{
            background: "radial-gradient(circle at 47.74248758951823% 27.788899739583332%, #5000FF 0%, 38.76%, rgba(80,0,255,0) 68%), radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #5000FF 0%, 13.200000000000001%, rgba(80,0,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(80,0,255,0.99) 0%, 25%, rgba(80,0,255,0) 50%), radial-gradient(circle at 5.416666666666667% 10%, #FF0051 0%, 42%, rgba(255,0,81,0) 70%), radial-gradient(circle at 93.90768686930339% 11.448923746744793%, #7500FF 0%, 42%, rgba(117,0,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)",
            height: '495px',
            pt: 4,  // Adds padding to the top
            px: 5,  // Adds padding to the left and right
            mb: 12
        }}>

            <Box sx={{
                minHeight: {sm: '500px'},
                height: 'auto',
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: '1px solid #BCBCBC',
                borderRadius: "20px",
                pt: 5,  // Adds padding to the top
                px: isSmallScreen ? 5 : 10,  // Adds padding to the left and right
            }}>

                <Grid container sx={{ mt: 3 }}>
                    <Grid item xs={12} md={6}>
                        {
                            isTabletMedium ? (
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="h5" sx={{ color: '#000000', fontFamily: "Alverata Bold Italic", mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Add Users
                                    </Typography>
                                    <Box sx={{ mt: "-6px !important" }}>
                                        <IconButton onClick={() => setOpenDialog(true)}>
                                            <PersonAddAltOutlinedIcon sx={{ height: 27, width: 27, color: '#7500FF' }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            ) : (
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="h5" sx={{ color: '#000000', fontFamily: "Alverata Bold Italic" }}>
                                        Add Users
                                    </Typography>
                                    <Box sx={{ mt: "-6px !important" }}>
                                        <IconButton onClick={() => setOpenDialog(true)}>
                                            <PersonAddAltOutlinedIcon sx={{ height: 27, width: 27, color: '#7500FF' }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            )
                        }
                    </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <AdminList />
                    </Grid>
                </Grid>

            </Box>

            <AddUser openDialog={openDialog} openDialogFunc={setOpenDialog} />
        </Box>

    </>
  )
}

export default SuperAdmin