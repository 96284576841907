import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Cookies, { set } from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';
import APP_CONFIG from '../../Const';

function datetimeConverter(dateString) {
    const datetimeString = dateString;
    const date = new Date(datetimeString);
    const epochsTime = date.getTime(); // Convert date to epochs time (milliseconds since January 1, 1970)
    const secondsTime = epochsTime / 1000; // Convert milliseconds to seconds
    return secondsTime;
}

const EditDialog = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const statusForm = ['Live', 'Scheduled', 'Complete'];
    const [selectedStatusMap, setSelectedStatusMap] = React.useState({});
    const [name, setName] = React.useState('');
    const [noTickets, setNoTickets] = React.useState('');
    const [eventDate, setEventDate] = React.useState('');
    const [category, setCategory] = React.useState('');


    const handleClose = () => {
      props.openDialogFunc(false);
    };

    const handleSave = () => {
        const item = {
            id: props.editData.id,
            datetime: eventDate === '' ? datetimeConverter(props.editData.eventDate) : datetimeConverter(eventDate),
            time: props.editData.time,
            venue: props.editData.venue,
            ticket_price: props.editData.revenue / props.editData.ticket_count,
            name: name === '' ? props.editData.name : name,
            category: category === '' ? props.editData.category : category,
            cat: 'cat',
            revenue: props.editData.revenue,
            total_tickets: noTickets === "" ? props.editData.ticket_count : noTickets,
            status: Object.keys(selectedStatusMap).length === 0 ? props.editData.status : Object.values(selectedStatusMap)[0],
        };
        // console.log(item)

        const config = {
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
            }
        }

        axios.post("https://api.wave.tickets/api/events/", item, config)
            .then((response) => {
                toast("Event Updated Successfully", {type: ["success"]})
                props.openDialogFunc(false);
                setTimeout(() => {
                    window.location.reload(true)
                  }, 1500);
            })
            .catch(err => {
                toast("Network error", {type: ["error"]})
                props.openDialogFunc(false);
            })
    }

    const handleEdit = (event, setState) => {
        setState(event.target.value)
    }

    const handleChange = (event) => {
        const selectedIndex = props.indexVal;
        const newSelectedStatus = event.target.value;
      
        setSelectedStatusMap((prevStatusMap) => ({
          ...prevStatusMap,
          [selectedIndex]: newSelectedStatus,
        }));
      };;
  
    return (
        <Dialog
          fullScreen={fullScreen}
          open={props.openDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >

          <DialogTitle id="responsive-dialog-title" sx={{ fontFamily: 'Filson Pro Bold' }}>
            {"Do you want to edit this event?"}
          </DialogTitle>

          <DialogContent>

            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {/* Name Edit */}
                    <TextField 
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        fullWidth
                        variant="standard"
                        defaultValue={props.editData && props.editData.name}
                        onChange={(event) => handleEdit(event, setName)}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline.Mui-focused:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#7500FF',
                                fontFamily: 'Alverata Md Italic',
                            },
                            '& .MuiInputBase-root': {
                                fontFamily: 'Filson Pro Light',
                            },
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    {/* No. of Tickets Edit */}
                    <TextField 
                        autoFocus={false}
                        margin="dense"
                        id="name"
                        label="No. of Tickets"
                        fullWidth
                        variant="standard"
                        defaultValue={props.editData && props.editData.ticket_count}
                        // disabled={props.editData && props.editData.ticket_count !== '0' ? true : false}
                        onChange={(event) => handleEdit(event, setNoTickets)}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline.Mui-focused:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#7500FF',
                                fontFamily: 'Alverata Md Italic',
                            },
                            '& .MuiInputBase-root': {
                                fontFamily: 'Filson Pro Light',
                            },
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    {/* Event Date */}
                    <TextField 
                        autoFocus={false}
                        margin="dense"
                        id="name"
                        label="Event Date"
                        fullWidth
                        variant="standard"
                        defaultValue={props.editData && props.editData.eventDate}
                        onChange={(event) => handleEdit(event, setEventDate)}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline.Mui-focused:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#7500FF',
                                fontFamily: 'Alverata Md Italic',
                            },
                            '& .MuiInputBase-root': {
                                fontFamily: 'Filson Pro Light',
                            },
                        }}
                    />

                </Grid>

                <Grid item xs={6}>
                    {/* Category */}
                    <TextField 
                        autoFocus={false}
                        margin="dense"
                        id="name"
                        label="Category"
                        fullWidth
                        variant="standard"
                        defaultValue={props.editData && props.editData.category}
                        onChange={(event) => handleEdit(event, setCategory)}
                        sx={{
                            '& .MuiInput-underline:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInput-underline.Mui-focused:before': {
                                borderBottomColor: '#7500FF',
                            },
                            '& .MuiInputLabel-root': {
                                color: '#7500FF',
                                fontFamily: 'Alverata Md Italic',
                            },
                            '& .MuiInputBase-root': {
                                fontFamily: 'Filson Pro Light',
                            },
                        }}
                    />

                </Grid>

                <Grid item xs={12}>
                    <FormControl sx={{ marginTop: 2, width: '100%' }}>
                        <InputLabel 
                            id="demo-multiple-checkbox-label"  
                            sx={{ 
                                fontFamily: 'Alverata Md Italic', 
                                color: '#7500FF' 
                            }}>
                                Event Status
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            fullWidth
                            value={selectedStatusMap[props.indexVal] || props.initialStatus}
                            onChange={handleChange}
                            input={
                                <OutlinedInput 
                                    label="Event Status"
                                    sx={{
                                        '&.MuiOutlinedInput-root': {
                                          '&.Mui-focused, &.Mui-hover': {
                                            borderColor: 'purple', // Change the border color when focused or hovered
                                          },
                                        },
                                    }}
                                />
                            }
                        >
                            {statusForm.map((name) => (
                                <MenuItem key={name} value={name}  sx={{ fontFamily: 'Filson Pro Regular' }}>
                                    <ListItemText primary={name}  />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={handleClose} sx={{ color: '#FF0065' }}>
              Cancel
            </Button>
            <Button onClick={handleSave} autoFocus sx={{ color: '#7500FF' }}>
              Save
            </Button>
          </DialogActions>

        </Dialog>
    );
}

export default EditDialog