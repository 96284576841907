import React from 'react';
import Cookies from 'js-cookie'
import axios from 'axios';
import { toast } from 'react-toastify';

// Custom component React
import Navbar from '../components/Navigation/Navbar';

// Material UI Component
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import HorizontalNav from '../components/Navigation/HorizontalNav';
import Category from '../components/filter/Category';
import Areachart from '../components/chart/Areachart';
import Searchbar from '../components/search/Searchbar';
import EventTable from '../components/tables/EventTable';


// Images and logo
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ButtonIcon from '../components/button/ButtonIcon';
import DateFilter from '../components/filter/DateFilter';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';

function createData(id, name, category, eventDate, createdBy, status, revenue, ticket_sold, ticket_count, venue, image, time, reminder) {
    return { id, name, category, eventDate, createdBy, status, revenue, ticket_sold, ticket_count, venue, image, time, reminder };
}

function datetime(dateString) {
    const datetimeString = dateString;
    const date = new Date(datetimeString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
}

const Dashboard = ({ activeTab, setActiveTab }) => {
    const isSmallScreen = useMediaQuery('(max-width:950px)')
    const isTablet = useMediaQuery('(max-width:830px)')
    const isTabletMedium = useMediaQuery('(max-width:900px)')
    const [dataSearch, setDataSearch] = React.useState([]);
    const [dataCategory, setDataCategory] = React.useState([]);
    const [filter, setFilter] = React.useState(null);
    const position = isTabletMedium ?  'center' : 'flex-end';
    const navigate = useNavigate();
    const location = useLocation();
    const token = Cookies.get("token");
    let typingTimeout = null;

    React.useEffect(() => {
        if (!token) {
          navigate('/')
        }
    }, [])

    React.useEffect(() => {
        if (location.pathname !== '/dashboard') {
            navigate('/dashboard');
        }
    }, [location, navigate]);

    const handleSearchBar = (event) => {
        const searchQuery = event.target.value
        clearTimeout(typingTimeout);

        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
                Accept: 'application/json'
            }
        };

        typingTimeout = setTimeout(() => {
            axios.get(`https://api.wave.tickets/api/events?search=${searchQuery}`, config)
            .then((response) => {

                const datamap = response.data.events.data.map((item) => {
                    let revenue_created = item.ticket_price * item.attendees_count;
                    return createData(
                        item.id,
                        item.name,
                        item.category,
                        datetime(item.datetime),
                        item.created_by.name,
                        item.status.charAt(0).toUpperCase() + item.status.slice(1),
                        revenue_created,
                        item.attendees_count,
                        item.total_tickets,
                        item.venue,
                        item.event_image,
                        item.time,
                        item.reminder
                    );
                });
                setDataSearch(datamap)
            })
            .catch((error) => {
                toast("No records found", { type: ["error"] });
            })
        }, 1000);
    }

    return (
        <>
            <Navbar />

            <Box sx={{
                background: "radial-gradient(circle at 47.74248758951823% 27.788899739583332%, #5000FF 0%, 38.76%, rgba(80,0,255,0) 68%), radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #5000FF 0%, 13.200000000000001%, rgba(80,0,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(80,0,255,0.99) 0%, 25%, rgba(80,0,255,0) 50%), radial-gradient(circle at 5.416666666666667% 10%, #FF0051 0%, 42%, rgba(255,0,81,0) 70%), radial-gradient(circle at 93.90768686930339% 11.448923746744793%, #7500FF 0%, 42%, rgba(117,0,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)",
                height: '495px',
                pt: 4,  // Adds padding to the top
                px: 5,  // Adds padding to the left and right
                mb: 12
            }}>

                <Box sx={{
                    minHeight: {sm: '800px'},
                    height: 'auto',
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: '1px solid #BCBCBC',
                    borderRadius: "20px",
                    pt: 5,  // Adds padding to the top
                    px: isSmallScreen ? 5 : 10,  // Adds padding to the left and right
                }}>

                    
                    <Grid container sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                            {
                                isTablet 
                                ? (
                                    <Box sx={{ width: isTablet && 400 }}>
                                        <HorizontalNav activeTab={activeTab} setActiveTab={setActiveTab} />
                                    </Box>
                                ) : (
                                    <HorizontalNav activeTab={activeTab} setActiveTab={setActiveTab} />
                                )
                            }
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 3 }}>
                        <Grid item xs={12} md={6}>
                            {
                                isTabletMedium ? (
                                    <Typography variant="h5" sx={{ color: '#000000', fontFamily: "Alverata Bold Italic", mb: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        Revenue
                                    </Typography>
                                ) : (
                                    <Typography variant="h5" sx={{ color: '#000000', fontFamily: "Alverata Bold Italic" }}>
                                        Revenue
                                    </Typography>
                                )
                            }
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: `${isTabletMedium ?  'center' : 'flex-end'}`, alignItems: 'center' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={5.25} md={5.5}>
                                    <Category label="Category" onFilter={setFilter} setCategory={setDataCategory} />
                                </Grid>
                                <Grid item xs={5.25} md={5.5}>
                                    <DateFilter label="Date" />
                                </Grid>
                                <Grid item xs={1.5} md={1}>
                                    <ButtonIcon icon={<FileDownloadOutlinedIcon sx={{ color: '#000000' }} />} filterValue={filter} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 4 }} >
                        <Grid item xs={12}>
                            <Areachart />
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 5, display: 'flex', flexDirection: 'row', }}>
                        <Grid item xs={6}>
                            <Stack direction="row" spacing={2.5}>
                                <Typography variant="h5" sx={{ color: '#00000', fontFamily: "Alverata Bold Italic" }} >Events</Typography>
                                <Box sx={{
                                    mt: "-5px !important" 
                                }}>
                                    <Searchbar onChangeHandlerSearch={handleSearchBar} />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <EventTable searchDataset={dataSearch} categoryDataset={dataCategory}/>
                        </Grid>
                    </Grid>

                </Box>
            </Box>

        </>
    )
}

export default Dashboard