import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios'
import Cookies from "js-cookie";

// Custom component React
import Navbarlogo from '../components/Navigation/Navbarlogo';
import InputText from '../components/inputfields/InputText';
import ElongatedButtonTranparent from '../components/button/ElongatedButtonTranparent';
import ElongatedButton from '../components/button/ElongatedButton';
import ElongatedButtonwithLogo from '../components/button/ElongatedButtonwithLogo';

// Material UI Component
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

// Images and logo
import logo from "../static/navigation/lightIcon.png";
import AppleIcon from '@mui/icons-material/Apple';
import GoogleIcon from '@mui/icons-material/Google';
import APP_CONFIG from '../Const';

const SignUp = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [textInputPositionEmail, setTextInputPositionEmail] = useState(0.8);
    const [textInputPositionPass, setTextInputPositionPass] = useState(0.8);
    const [textInputPositionName, setTextInputPositionName] = useState(0.8);
    const [textInputPositionCpass, setTextInputPositionCpass] = useState(0.8);

    const isSmallScreen = useMediaQuery('(max-width:1200px)')
  
    // handle Functions
    const handleInputEmail = (event) => {
        setEmail(event.target.value);
      if (event.target.value.length > 0) {
        setTextInputPositionEmail(-1);
      } else {
        setTextInputPositionEmail(0.8)
      }
    }
  
    const handleInputPassword = (event) => {
      setPassword(event.target.value);
      if (event.target.value.length > 0) {
        setTextInputPositionPass(-1);
      } else {
        setTextInputPositionPass(0.8)
      }
    }

    const handleInputFullName = (event) => {
        setUsername(event.target.value);
        if (event.target.value.length > 0) {
            setTextInputPositionName(-1);
        } else {
            setTextInputPositionName(0.8)
        }
    }

    const handleInputConfirmPassword = (event) => {
        setConfirmPassword(event.target.value);
        if (event.target.value.length > 0) {
            setTextInputPositionCpass(-1);
        } else {
            setTextInputPositionCpass(0.8)
        }
    }
  
    const handleSignUp = (event) => {
      axios.post("https://api.wave.tickets/api/auth/register", {
        name: username,
        email: email,
        password : password,
        confirmPassword: confirmPassword,
      })
      .then((response) => {
        toast(response.data.message, { type: ["success"] })
        setTimeout(() => {
          navigate('/');
        }, 1000)
      }).catch((err) => {
        toast("Credentials are invalid", { type: ["error"] })
      })
    }

  return (
    <>
        <Navbarlogo />

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // minHeight: '100vh',  // Adjusted the height
          height: isSmallScreen ? 'calc(100vh - 50px)' : 'calc(100vh - 64px)',
          overflow: 'hidden',  // Add this line
          background: "transparent linear-gradient(270deg, #7500FF 0%, #FF0065 100%) 0% 0% no-repeat padding-box;",
        }}
        >

        <Box sx={{
          width: 350,
          mt: -8,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent', // Webkit: Hide scrollbar
          },
          scrollbarWidth: 'none',  // Firefox: Hide scrollbar
          msOverflowStyle: 'none',  // IE: Hide scrollbar
          p: 2,
        }}>

          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Box
              component="img"
              sx={{
                height: 200,
                width: "auto",  // Corrected the typo here
                display: { xs: 'none', md: 'flex', color: '#000' },
              }}
              alt="Wavelogo"
              src={logo}
            />

            <Box
              component="img"
              sx={{
                height: 200,
                width: "auto",  // Corrected the typo here
                display: { xs: 'flex', md: 'none', color: '#000' },
              }}
              alt="Wavelogo"
              src={logo}
            />

            </Grid>
          </Grid>

          <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <InputText id="Fullname" label="Fullname" sx={{ fontFamily: 'Filson Pro Thin' }} type="text" textPos={textInputPositionName} onInputChange={handleInputFullName}  />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <InputText id="Email" label="email" sx={{ fontFamily: 'Filson Pro Thin' }} type="text" textPos={textInputPositionEmail} onInputChange={handleInputEmail}  />
            </Grid>
            
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <InputText id="password" label="Password" type="password" textPos={textInputPositionPass} onInputChange={handleInputPassword} />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <InputText id="cpassword" label="Confirm Password" type="password" textPos={textInputPositionCpass} onInputChange={handleInputConfirmPassword} />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ElongatedButtonTranparent label="Sign Up" fontFam="Filson Pro Bold" onClick={handleSignUp} />
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
              <Typography sx={{ color: "#fff", fontWeight: 300 }} >
                Already have a account &nbsp;
                <Link style={{ color: '#fff'}} to="/">Login</Link>
              </Typography>
            </Grid>

          </Grid>

        </Box>

      </Box>
    </>
  )
}

export default SignUp