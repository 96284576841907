import React, { useState } from 'react';

// Custom component React

// Material UI Component
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';

// Images and logo
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/system';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
    padding: '5px 14px 12px 14px',
    lineHeight: '2'
  }
});

const categoryFilter = [
    { label: "1 Month", value: "filter1" },
    { label: "3 Month", value: "filter2" },
    { label: "6 Month", value: "filter3" },
    { label: "1 Year", value: "filter4" },
]


const DateFilter = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const isMediumScreen = useMediaQuery('(max-width:1020px)')
  
    const fontSizeValue = isMediumScreen ? '10px' : '15px';
  
    return (
      <StyledAutocomplete 
          disablePortal
          id="category-filter"
          options={categoryFilter}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label={!isFocused ? props.label : null}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              sx={{
                width: '100%',
                borderRadius: '32px',
                border: '1px solid #BCBCBC',
                fontFamily: 'Filson Pro Regular',
                '& fieldset': {
                    borderRadius: '32px',
                    border: '1px solid #BCBCBC',
                    fontFamily: 'Filson Pro Regular',
                },
                '&:hover': {
                    borderColor: '#4D00FF',
                },
                '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#4D00FF',
                },
                '& .MuiInputLabel-root.MuiInputLabel-shrink': {
                    display: 'none',
                },
                '& .MuiInputBase-root': {
                    height: '47px',
                    alignItems: 'center',
                    display: 'flex',
                    fontWeight: 400,
                    p: '0 0 0 10px',
                    fontSize: fontSizeValue,
                },
                '& .MuiInputLabel-root': {
                    color: '#000000',  // set label color to white
                    fontWeight: 500,
                    mt: -0.40,
                    ml: 0.4,
                    fontFamily: 'Filson Pro Regular',
                  },
              }} 
            />
          )}
          sx={{ 
              width: '100%',
              '& .MuiAutocomplete-popupIndicator': {
                  color: '#4D00FF', // replace with your color
              },
              '&.Mui-focused .MuiAutocomplete-popupIndicator': {
                  color: '#4D00FF',
              }
          }}
          popupIcon={
              <div style={{
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
                border: '1px solid #4D00FF'
              }}>
                <ArrowDropDownIcon style={{ color: '#4D00FF' }} />
              </div>
            }
      />
    )
}

export default DateFilter