// React builtin components imports
import React, {useState} from 'react'

// Third-party components imports
import { Routes, Route } from 'react-router';

// Custom component imports
import Login from './auth/Login';
import Dashboard from './layout/Dashboard';
import AddEvent from './layout/AddEvent';
import Reminders from './layout/Reminders';
import Contacts from './layout/Contacts';
import Billing from './layout/Billing';
import SignUp from './auth/SignUp';
import SuperAdmin from './layout/SuperAdmin';
import StripePayment from './layout/StripePayment';

const AppRoutes = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard activeTab={activeTab} setActiveTab={setActiveTab} />} />
        <Route path="/add-event" element={<AddEvent activeTab={activeTab} setActiveTab={setActiveTab} />} />
        <Route path="/reminders" element={<Reminders activeTab={activeTab} setActiveTab={setActiveTab} />} />
        <Route path="/contacts" element={<Contacts activeTab={activeTab} setActiveTab={setActiveTab} />} />
        <Route path="/billing" element={<Billing activeTab={activeTab} setActiveTab={setActiveTab} />} />
        <Route path="/super-admin" element={<SuperAdmin />} />
        <Route path="/payment" element={<StripePayment />} />
        <Route path="/singup" element={<SignUp />} />
    </Routes>
  )
}

export default AppRoutes