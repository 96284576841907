import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from 'axios'
import Cookies from "js-cookie";
import {Button, TextField} from '@mui/material';

// Custom component React
import Navbarlogo from '../components/Navigation/Navbarlogo';
import InputText from '../components/inputfields/InputText';
import ElongatedButton from '../components/button/ElongatedButton';

// Material UI Component
import { Box, Grid, useMediaQuery } from '@mui/material';

// Images and logo
import logo from "../static/navigation/waveWhiteNew.png";
import ElongatedButtonTranparent from '../components/button/ElongatedButtonTranparent';
import APP_CONFIG from '../Const';

const Login = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [textInputPosition, setTextInputPosition] = useState(0.8);
  const [textInputPositionPass, setTextInputPositionPass] = useState(0.8);
  const token = Cookies.get("token")
  const adminAccess = Cookies.get("is_admin")

  const isSmallScreen = useMediaQuery('(max-width:1200px)')

  React.useEffect(() => {
    if(token){
      // console.log(adminAccess)
      if (adminAccess === '1') {
        navigate('/super-admin')
      } else {
        navigate('/dashboard')
      }
    }
  }, [])

  // handle Functions
  const handleInputEmail = (event) => {
    setUsername(event.target.value);
    if (event.target.value.length > 0) {
      setTextInputPosition(-1);
    } else {
      setTextInputPosition(0.8)
    }
  }

  const handleInputPassword = (event) => {
    setPassword(event.target.value);
    if (event.target.value.length > 0) {
      setTextInputPositionPass(-1);
    } else {
      setTextInputPositionPass(0.8)
    }
  }

  const handleSubmit = (event) => {
    // console.log("printme")
    // console.log(username, password)
    event.preventDefault();
    if (username === '' || password === '') {
      toast("Please fill all the fields", { type: ["error"] })
      return;
    }
    axios.post("https://api.wave.tickets/api/auth/login", {
      email: username,
      password: password
    })
    .then((response) => {
      // console.log(response.data)
      if (response.data.user.is_admin === 1) {
        // console.log(response.data)
        Cookies.set("token", response.data.token)
        Cookies.set("name", response.data.user.name)
        Cookies.set("email", response.data.user.email)
        Cookies.set("avatar", response.data.user.image)
        Cookies.set("user_id", response.data.user.id)
        Cookies.set("is_admin", response.data.user.is_admin)
        toast(response.data.message, { type: ["success"] })
        if (Cookies.get("token")){
          navigate('/super-admin');
        }
      }
      else { 
        Cookies.set("token", response.data.token)
        Cookies.set("name", response.data.user.name)
        Cookies.set("email", response.data.user.email)
        Cookies.set("avatar", response.data.user.image)
        Cookies.set("user_id", response.data.user.id)
        toast(response.data.message, { type: ["success"] })
        if (Cookies.get("token")){
          navigate('/dashboard');
        }
      }
    }).catch((err) => {
      toast("Credentials are invalid", { type: ["error"] })
    })
  }

  return (
    <>
      {/* <Navbarlogo /> */}

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // minHeight: '100vh',  // Adjusted the height
        height: isSmallScreen ? 'calc(100vh - 50px)' : 'calc(100vh - 2px)',
        overflow: 'hidden',  // Add this line
        border: '1px solid #707070',
        background: "radial-gradient(circle at 47.74248758951823% 27.788899739583332%, #5000FF 0%, 38.76%, rgba(80,0,255,0) 68%), radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #5000FF 0%, 13.200000000000001%, rgba(80,0,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(80,0,255,0.99) 0%, 25%, rgba(80,0,255,0) 50%), radial-gradient(circle at 5.416666666666667% 10%, #FF0051 0%, 42%, rgba(255,0,81,0) 70%), radial-gradient(circle at 93.90768686930339% 11.448923746744793%, #7500FF 0%, 42%, rgba(117,0,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)",
      }}>

        <Box sx={{
          width: 350,
          // mt: -8,
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '0px',
            background: 'transparent', // Webkit: Hide scrollbar
          },
          scrollbarWidth: 'none',  // Firefox: Hide scrollbar
          msOverflowStyle: 'none',  // IE: Hide scrollbar
          p: 2,
        }}>
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

              <Box
                component="img"
                sx={{
                  height: "40px",
                  mb: 3,
                  width: "auto",  // Corrected the typo here
                  display: { xs: 'none', md: 'flex', color: '#000' },
                }}
                alt="Wavelogo"
                src={logo}
              />

              <Box
                component="img"
                sx={{
                  height: 200,
                  width: "auto",  // Corrected the typo here
                  display: { xs: 'flex', md: 'none', color: '#000' },
                }}
                alt="Wavelogo"
                src={logo}
              />

              </Grid>
            </Grid>

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
            >
              <TextField
                placeholder="Email"
                id="email"
                variant="outlined"
                onChange={handleInputEmail}
                type="email"
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '32px',
                    border: '2px solid #FFFFFF',
                    background: '#FFFFFF63',
                    width: "356px",
                    height: "52px",
                    '&:hover': {
                      borderColor: '#FFFFFF',
                    },
                    '&.Mui-focused': {
                      borderColor: '#FFFFFF',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFFFFF',  // set label color to white
                    fontWeight: 300,
                    letterSpacing: '0.5px',
                    // mt: props.textPos,
                    pl: 2
                    // ml: 2,
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFFFFF',  // set input text color to white
                    pl: 3
                    // ml: 2,
                  },
                }}
              />

              <Box sx={{ mt: 1.8 }} />
              <TextField
                placeholder="Password"
                id="password"
                variant="outlined"
                onChange={handleInputPassword}
                type="password"
                sx={{ 
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '32px',
                    border: '2px solid #FFFFFF',
                    background: '#FFFFFF63',
                    width: "356px",
                    height: "52px",
                    '&:hover': {
                      borderColor: '#FFFFFF',
                    },
                    '&.Mui-focused': {
                      borderColor: '#FFFFFF',
                    },
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiInputLabel-root': {
                    color: '#FFFFFF',  // set label color to white
                    fontWeight: 300,
                    letterSpacing: '0.5px',
                    // mt: props.textPos,
                    pl: 2
                    // ml: 2,
                  },
                  '& .MuiInputBase-input': {
                    color: '#FFFFFF',  // set input text color to white
                    pl: 3
                    // ml: 2,
                  },
                }} 
              />

              <Box sx={{ mt: 1.8 }} />
              <Grid container spacing={0.5}>
                <Grid item xs={6}>
                  <Box sx={{ 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    }}
                  >
                    <Button 
                        type="submit"
                        variant="outlined"
                        sx={{ 
                            color: '#fff',
                            height: '52px',
                            width: '172px',
                            border: '2px solid #FFFFFF',
                            borderRadius: "32px",
                            textTransform: 'none',
                            fontSize: '20px',
                            fontFamily: "Filson Pro Bold",
                            "&:hover": {
                                color: '#fff',
                                border: '2px solid #FFFFFF',
                                borderRadius: "32px",
                            }
                        }}
                    >
                        Sign Up
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    }}
                  >
                    <Button 
                        type="submit"
                        variant="outlined"
                        sx={{ 
                            color: '#2A00FF',
                            height: '52px',
                            width: '172px',
                            background: '#FFFFFF 0% 0% no-repeat padding-box',
                            border: '2px solid #FFFFFF',
                            borderRadius: "32px",
                            textTransform: 'none',
                            fontSize: '20px',
                            fontFamily: "Filson Pro Bold",
                            "&:hover": {
                                color: '#2A00FF',
                                background: '#FFFFFF 0% 0% no-repeat padding-box',
                                border: '2px solid #FFFFFF',
                                borderRadius: "32px",
                            }
                        }}
                      >
                          Sign In
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>

        </Box>

      </Box>
    </>
  )
}

export default Login;
