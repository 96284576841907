import React, { useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";

// Material UI Icons
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import EditIcon from "@mui/icons-material/Edit";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";

// Material UI component
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { IconButton, Box, useMediaQuery } from "@mui/material";
import EditDialog from "../dialogs/EditDialog";
import InfoDialog from "../dialogs/InfoDialog";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import APP_CONFIG from "../../Const";

const StyledRow = styled(TableRow)(() => ({
  "&:hover": {
    backgroundColor: "#E4D6FF",
    "& .MuiTableCell-root": {
      backgroundColor: "#E4D6FF",
    },
  },
}));

const StyledCell = styled(TableCell)(() => ({
  backgroundColor: "transparent",
}));

const name = {
  id: "name",
  label: "Name",
  minWidth: 20,
  align: "left",
};

const category = {
  id: "category",
  label: "Category",
  minWidth: 17,
  align: "left",
};

const eventDate = {
  id: "eventDate",
  label: "Event Date",
  minWidth: 20,
  align: "left",
};

const createdBy = {
  id: "createdBy",
  label: "Created By",
  minWidth: 20,
  align: "left",
};

const status = {
  id: "status",
  label: "Status",
  minWidth: 20,
  align: "left",
};

const revenue = {
  id: "revenue",
  label: "Revenue",
  minWidth: 17,
  align: "left",
};

const ticket_sold = {
  id: "result",
  label: "Result",
  minWidth: 17,
  align: "left",
};

const edit = {
  id: "edit",
  label: "",
  minWidth: 17,
  align: "left",
};

function createData(
  id,
  name,
  category,
  eventDate,
  createdBy,
  status,
  revenue,
  ticket_sold,
  ticket_count,
  venue,
  image,
  time,
  reminder
) {
  return {
    id,
    name,
    category,
    eventDate,
    createdBy,
    status,
    revenue,
    ticket_sold,
    ticket_count,
    venue,
    image,
    time,
    reminder,
  };
}

function datetime(dateString) {
  const datetimeString = dateString;
  const date = new Date(datetimeString);
  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate;
}

const EventTable = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [editableRow, setEditableRow] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [infoEvent, setInfoEvent] = React.useState(false);
  const [indexVal, setIndexVal] = React.useState("");
  const [initialStatus, setInitialStatus] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [dataDisplay, setDataDisplay] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [loadingMoreData, setLoadingMoreData] = React.useState(false);

  const isMediumScreen = useMediaQuery("(max-width:1040px)");
  const isSmallScreen = useMediaQuery("(max-width:900px)");

  const fontSize = !isMediumScreen ? "15px" : isSmallScreen ? "7.5px" : "11px";

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (
      scrollHeight - scrollTop <= clientHeight + 5 &&
      hasMore &&
      !loadingMoreData
    ) {
      setLoadingMoreData(true);

      setTimeout(() => {
        setPage((prevPage) => prevPage + 1);
        setLoadingMoreData(false);
      }, 500);
    }
  };

  React.useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        Accept: "application/json",
      },
    };

    if (page === 0) {
      setIsLoading(true); // Show loader for initial data load
    } else {
      setLoadingMoreData(true); // Show loader for subsequent data loads
    }

    axios
      .get(`https://api.wave.tickets/api/events?page=${page + 1}`, config)
      .then((response) => {
        const datamap = response.data.events.data.map((item) => {
          let revenue_created = item.ticket_price * item.attendees_count;
          return createData(
            item.id,
            item.name,
            item.category,
            datetime(item.datetime),
            item.created_by.name,
            item.status.charAt(0).toUpperCase() + item.status.slice(1),
            revenue_created,
            item.attendees_count,
            item.total_tickets,
            item.venue,
            item.event_image,
            item.time,
            item.reminder
          );
        });

        setDataDisplay((prevData) => [...prevData, ...datamap]);

        if (response.data.events.data.length < rowsPerPage) {
          setHasMore(false); // Indicate no more data to load
        }

        if (page === 0) {
          setIsLoading(false); // Hide loader for initial data load
        } else {
          setLoadingMoreData(false); // Hide loader for subsequent data loads
        }
      })
      .catch((err) => {
        toast("Network Error - Bad Request", { type: ["error"] });

        if (page === 0) {
          setIsLoading(false);
        } else {
          setLoadingMoreData(false);
        }
      });
  }, [page]);

  // console.log(props.categoryDataset)

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mb: 2,
      }}
    >
      <CircularProgress color="secondary" />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          overflowX: "scroll",
        }}
      >
        <TableContainer
          sx={{
            maxHeight: 360,
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none" /* IE and Edge */,
          }}
          onScroll={handleScroll}
        >
          <Table stickyHeader aria-label="sticky table" sx={{ width: "98%" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  key={name.id}
                  align={name.align}
                  style={{ minWidth: name.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {name.label}
                </TableCell>
                <TableCell
                  key={category.id}
                  align={category.align}
                  style={{ minWidth: category.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {category.label}
                </TableCell>
                <TableCell
                  key={eventDate.id}
                  align={eventDate.align}
                  style={{ minWidth: eventDate.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {eventDate.label}
                </TableCell>
                <TableCell
                  key={createdBy.id}
                  align={createdBy.align}
                  style={{ minWidth: createdBy.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {createdBy.label}
                </TableCell>
                <TableCell
                  key={status.id}
                  align={status.align}
                  style={{ minWidth: status.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {status.label}
                </TableCell>
                <TableCell
                  key={revenue.id}
                  align={revenue.align}
                  style={{ minWidth: revenue.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {revenue.label}
                </TableCell>
                <TableCell
                  key={ticket_sold.id}
                  align={ticket_sold.align}
                  style={{ minWidth: ticket_sold.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {ticket_sold.label}
                </TableCell>
                <TableCell
                  key={edit.id}
                  align={edit.align}
                  style={{ minWidth: edit.minWidth }}
                  sx={{
                    borderBottom: "none",
                    fontFamily: "Filson Pro Regular",
                    fontSize: fontSize,
                  }}
                >
                  {edit.label}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {props.searchDataset && props.searchDataset.length !== 0
                ? props.searchDataset.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <StyledRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={"styled-" + index}
                        >
                          <StyledCell
                            align={name.align}
                            sx={{
                              borderRadius: "32px 0 0 32px",
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Regular",
                              display: "flex",
                              flexDirection: "row",
                              fontSize: fontSize,
                              pt: "23px",
                              "&:hover": {
                                cursor: "pointer",
                                borderRadius: "32px 0 0 32px",
                              },
                            }}
                            onClick={() => {
                              setOpenInfo(true);
                              setInfoEvent(row);
                            }}
                          >
                            {row.name}
                            <Box sx={{ marginTop: "1px" }}>
                              {row.reminder !== null &&
                                row.reminder.status === 1 && (
                                  <AccessAlarmsIcon
                                    sx={{
                                      height: 12,
                                      width: 12,
                                      ml: 0.5,
                                      color: "green",
                                    }}
                                  />
                                )}
                            </Box>
                          </StyledCell>
                          <StyledCell
                            align={category.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.category}
                          </StyledCell>
                          <StyledCell
                            align={eventDate.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.eventDate}
                          </StyledCell>
                          <StyledCell
                            align={createdBy.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.createdBy}
                          </StyledCell>
                          <StyledCell
                            align={status.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.status === "Live" ? (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    borderRadius: "20px",
                                    border: "1px solid #4D00FF",
                                    background: "#fff",
                                    width: "84px",
                                  }}
                                >
                                  <PlayCircleIcon
                                    sx={{
                                      height: 15,
                                      width: 15,
                                      mr: 1,
                                      color: "#FF0065",
                                      ml: 1,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#4D00FF",
                                      fontFamily: "Filson Pro Medium",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Box>
                              </>
                            ) : row.status === "Scheduled" ? (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    borderRadius: "20px",
                                    border: "1px solid #BBBBBB",
                                    background: "#fff",
                                    width: "120px",
                                  }}
                                >
                                  <WatchLaterIcon
                                    sx={{
                                      height: 15,
                                      width: 15,
                                      mr: 1,
                                      color: "#BBBBBB",
                                      ml: 1,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#BBBBBB",
                                      fontFamily: "Filson Pro Thin",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    borderRadius: "20px",
                                    border: "1px solid #4D00FF",
                                    background: "#fff",
                                    width: "115px",
                                  }}
                                >
                                  <CheckCircleIcon
                                    sx={{
                                      height: 15,
                                      width: 15,
                                      mr: 1,
                                      color: "#4D00FF",
                                      ml: 1,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#4D00FF",
                                      fontFamily: "Filson Pro Light",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Box>
                              </>
                            )}
                          </StyledCell>
                          <StyledCell
                            align={revenue.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Bold",
                              fontSize: fontSize,
                            }}
                          >
                            {row.revenue !== "$0.00" ? (
                              <>
                                <span style={{ color: "#4D00FF" }}>
                                  ${row.revenue}
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Regular",
                                  }}
                                >
                                  ${row.revenue}
                                </span>
                              </>
                            )}
                          </StyledCell>
                          <StyledCell
                            align={ticket_sold.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Bold",
                              fontSize: fontSize,
                            }}
                          >
                            {row.ticket_sold !== "0" ? (
                              <>
                                <span
                                  style={{
                                    color: "#FF0065",
                                    fontFamily: "Filson Pro Bold",
                                  }}
                                >
                                  {row.ticket_sold}
                                </span>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Light",
                                  }}
                                >
                                  /
                                </span>
                                {row.ticket_count}
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Regular",
                                  }}
                                >
                                  {row.ticket_sold}
                                </span>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Light",
                                  }}
                                >
                                  /
                                </span>
                                {row.ticket_count}
                              </>
                            )}
                          </StyledCell>
                          <StyledCell
                            align={edit.align}
                            sx={{
                              borderRadius: "0px 32px 32px 0px",
                              border: "1px solid #C7C7C7",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Regular",
                              fontSize: fontSize,
                            }}
                          >
                            <IconButton
                              sx={{
                                mt: "1px !important",
                                height: 15,
                                width: 15,
                              }}
                              onClick={() => {
                                setOpen(true);
                                setEditableRow(row);
                                setIndexVal(index);
                                setInitialStatus(row.status);
                              }}
                            >
                              <EditIcon
                                sx={{ height: 20, width: 20, color: "#7500FF" }}
                              />
                            </IconButton>
                          </StyledCell>
                        </StyledRow>
                        <TableRow
                          style={{ height: "5px" }}
                          key={"space-" + index}
                        ></TableRow>
                      </React.Fragment>
                    );
                  })
                : dataDisplay.map((row, index) => {
                    return (
                      <React.Fragment key={index}>
                        <StyledRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={"styled-" + index}
                        >
                          <StyledCell
                            align={name.align}
                            sx={{
                              borderRadius: "32px 0 0 32px",
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Regular",
                              display: "flex",
                              flexDirection: "row",
                              fontSize: fontSize,
                              pt: "23px",
                              "&:hover": {
                                cursor: "pointer",
                                borderRadius: "32px 0 0 32px",
                              },
                            }}
                            onClick={() => {
                              setOpenInfo(true);
                              setInfoEvent(row);
                            }}
                          >
                            {row.name}
                            <Box sx={{ marginTop: "1px" }}>
                              {row.reminder !== null &&
                                row.reminder.status === 1 && (
                                  <AccessAlarmsIcon
                                    sx={{
                                      height: 12,
                                      width: 12,
                                      ml: 0.5,
                                      color: "green",
                                    }}
                                  />
                                )}
                            </Box>
                          </StyledCell>
                          <StyledCell
                            align={category.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.category}
                          </StyledCell>
                          <StyledCell
                            align={eventDate.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.eventDate}
                          </StyledCell>
                          <StyledCell
                            align={createdBy.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.createdBy}
                          </StyledCell>
                          <StyledCell
                            align={status.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Light",
                              fontSize: fontSize,
                            }}
                          >
                            {row.status === "Live" ? (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    borderRadius: "20px",
                                    border: "1px solid #4D00FF",
                                    background: "#fff",
                                    width: "84px",
                                  }}
                                >
                                  <PlayCircleIcon
                                    sx={{
                                      height: 15,
                                      width: 15,
                                      mr: 1,
                                      color: "#FF0065",
                                      ml: 1,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#4D00FF",
                                      fontFamily: "Filson Pro Medium",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Box>
                              </>
                            ) : row.status === "Scheduled" ? (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    borderRadius: "20px",
                                    border: "1px solid #BBBBBB",
                                    background: "#fff",
                                    width: "120px",
                                  }}
                                >
                                  <WatchLaterIcon
                                    sx={{
                                      height: 15,
                                      width: 15,
                                      mr: 1,
                                      color: "#BBBBBB",
                                      ml: 1,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#BBBBBB",
                                      fontFamily: "Filson Pro Thin",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Box>
                              </>
                            ) : (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                    borderRadius: "20px",
                                    border: "1px solid #4D00FF",
                                    background: "#fff",
                                    width: "115px",
                                  }}
                                >
                                  <CheckCircleIcon
                                    sx={{
                                      height: 15,
                                      width: 15,
                                      mr: 1,
                                      color: "#4D00FF",
                                      ml: 1,
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#4D00FF",
                                      fontFamily: "Filson Pro Light",
                                    }}
                                  >
                                    {row.status}
                                  </span>
                                </Box>
                              </>
                            )}
                          </StyledCell>
                          <StyledCell
                            align={revenue.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Bold",
                              fontSize: fontSize,
                            }}
                          >
                            {row.revenue !== "$0.00" ? (
                              <>
                                <span style={{ color: "#4D00FF" }}>
                                  ${row.revenue}
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Regular",
                                  }}
                                >
                                  ${row.revenue}
                                </span>
                              </>
                            )}
                          </StyledCell>
                          <StyledCell
                            align={ticket_sold.align}
                            sx={{
                              border: "1px solid #C7C7C7",
                              borderRight: "none",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Bold",
                              fontSize: fontSize,
                            }}
                          >
                            {row.ticket_sold !== "0" ? (
                              <>
                                <span
                                  style={{
                                    color: "#FF0065",
                                    fontFamily: "Filson Pro Bold",
                                  }}
                                >
                                  {row.ticket_sold}
                                </span>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Light",
                                  }}
                                >
                                  /
                                </span>
                                {row.ticket_count}
                              </>
                            ) : (
                              <>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Regular",
                                  }}
                                >
                                  {row.ticket_sold}
                                </span>
                                <span
                                  style={{
                                    color: "#BBBBBB",
                                    fontFamily: "Filson Pro Light",
                                  }}
                                >
                                  /
                                </span>
                                {row.ticket_count}
                              </>
                            )}
                          </StyledCell>
                          <StyledCell
                            align={edit.align}
                            sx={{
                              borderRadius: "0px 32px 32px 0px",
                              border: "1px solid #C7C7C7",
                              borderLeft: "none",
                              background: "#F7F3FF",
                              fontFamily: "Filson Pro Regular",
                              fontSize: fontSize,
                            }}
                          >
                            <IconButton
                              sx={{
                                mt: "1px !important",
                                height: 15,
                                width: 15,
                              }}
                              onClick={() => {
                                setOpen(true);
                                setEditableRow(row);
                                setIndexVal(index);
                                setInitialStatus(row.status);
                              }}
                            >
                              <EditIcon
                                sx={{ height: 20, width: 20, color: "#7500FF" }}
                              />
                            </IconButton>
                          </StyledCell>
                        </StyledRow>
                        <TableRow
                          style={{ height: "5px" }}
                          key={"space-" + index}
                        ></TableRow>
                      </React.Fragment>
                    );
                  })}

              {/* {(props.searchDataset && props.searchDataset.length === 0) || (props.categoryDataset && props.categoryDataset.length === 0) ?
                                    (props.searchDataset && props.searchDataset.length !== 0 ? 
                                        props.searchDataset.map((row, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                <StyledRow hover role="checkbox" tabIndex={-1} key={'styled-' + index} >
                                                    <StyledCell 
                                                        align={name.align} 
                                                        sx={{ 
                                                            borderRadius: "32px 0 0 32px",
                                                            border: "1px solid #C7C7C7", 
                                                            borderRight: "none", 
                                                            background: '#F7F3FF', 
                                                            fontFamily: "Filson Pro Regular",
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            fontSize: fontSize,
                                                            pt: '23px',
                                                            '&:hover': {
                                                                cursor: 'pointer',
                                                                borderRadius: "32px 0 0 32px",
                                                            }
                                                        }} 
                                                        onClick={() => {
                                                            setOpenInfo(true)
                                                            setInfoEvent(row)
                                                        }}
                                                    >
                                                        
                                                        {row.name} 
                                                        <Box sx={{ marginTop: '1px' }}>
                                                            {
                                                                row.reminder !== null && (
                                                                    row.reminder.status === 1 && (
                                                                        <AccessAlarmsIcon sx={{ height: 12, width: 12, ml:0.5, color: "green" }} />
                                                                    )
                                                                )
                                                            }
                                                        </Box>
                                                    </StyledCell>
                                                    <StyledCell align={category.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                        {row.category}
                                                    </StyledCell>
                                                    <StyledCell align={eventDate.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                        {row.eventDate}
                                                    </StyledCell>
                                                    <StyledCell align={createdBy.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                        {row.createdBy}
                                                    </StyledCell>
                                                    <StyledCell align={status.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                        {
                                                            row.status === "Live" 
                                                            ?
                                                                <>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #4D00FF', background: '#fff', width: "84px" }}>
                                                                            <PlayCircleIcon sx={{ height: 15, width: 15, mr: 1, color: "#FF0065", ml: 1 }} />
                                                                            <span style={{ color: "#4D00FF", fontFamily: "Filson Pro Medium" }}>
                                                                                {row.status}
                                                                            </span>
                                                                    </Box>
                                                                </>
                                                            :
                                                                row.status === "Scheduled" 
                                                                ?
                                                                    <>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #BBBBBB', background: '#fff', width: "120px"}}>
                                                                                <WatchLaterIcon sx={{ height: 15, width: 15, mr: 1, color: "#BBBBBB", ml: 1 }} />
                                                                                <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Thin" }}>
                                                                                    {row.status}
                                                                                </span>
                                                                        </Box>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #4D00FF', background: '#fff', width: "115px"}}>
                                                                                <CheckCircleIcon sx={{ height: 15, width: 15, mr: 1, color: "#4D00FF", ml: 1 }} />
                                                                                <span style={{ color: "#4D00FF", fontFamily: "Filson Pro Light" }}>
                                                                                    {row.status}
                                                                                </span>
                                                                        </Box>
                                                                    </>
                        
                        
                                                        }
                                                    </StyledCell>
                                                    <StyledCell align={revenue.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Bold", fontSize: fontSize }}>
                                                    {
                                                            row.revenue !== '$0.00' 
                                                                ? 
                                                                    <>
                                                                        <span style={{ color: "#4D00FF" }}>
                                                                            ${row.revenue}
                                                                        </span>
                                                                    </>
                                                                :
                                                                    <>
                                                                        <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Regular"}}>
                                                                            ${row.revenue}
                                                                        </span>
                                                                    </>
                                                        }
                                                    </StyledCell>
                                                    <StyledCell align={ticket_sold.align} sx={{  border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Bold", fontSize: fontSize }}>
                                                        {
                                                            row.ticket_sold !== '0' 
                                                                ? 
                                                                    <>
                                                                        <span style={{ color: "#FF0065", fontFamily: "Filson Pro Bold" }}>
                                                                            {row.ticket_sold}
                                                                        </span>
                                                                        <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Light" }}>
                                                                            /
                                                                        </span>
                                                                        {row.ticket_count}
                                                                    </>
                                                                :
                                                                    <>
                                                                        <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Regular"}}>
                                                                            {row.ticket_sold}
                                                                        </span>
                                                                        <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Light" }}>
                                                                            /
                                                                        </span>
                                                                        {row.ticket_count}
                                                                    </>
                                                        }
                                                    </StyledCell>
                                                    <StyledCell align={edit.align} sx={{ borderRadius: "0px 32px 32px 0px", border: "1px solid #C7C7C7", borderLeft: "none", background: '#F7F3FF', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                                        <IconButton sx={{ mt: "1px !important", height: 15, width: 15, }} onClick={() => {
                                                                setOpen(true)
                                                                setEditableRow(row)
                                                                setIndexVal(index)
                                                                setInitialStatus(row.status)
                                                                }}
                                                            >
                                                            <EditIcon sx={{ height: 20, width: 20, color: '#7500FF' }} />
                                                        </IconButton>
                                                    </StyledCell>
                                                </StyledRow>
                                                <TableRow style={{ height: '5px' }} key={'space-' + index}></TableRow>
                                                </React.Fragment>
                                            );
                                        })
                                        :
                                        props.categoryDataset && props.categoryDataset.length !== 0 &&
                                            props.categoryDataset.map((row, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                    <StyledRow hover role="checkbox" tabIndex={-1} key={'styled-' + index} >
                                                        <StyledCell 
                                                            align={name.align} 
                                                            sx={{ 
                                                                borderRadius: "32px 0 0 32px",
                                                                border: "1px solid #C7C7C7", 
                                                                borderRight: "none", 
                                                                background: '#F7F3FF', 
                                                                fontFamily: "Filson Pro Regular",
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                fontSize: fontSize,
                                                                pt: '23px',
                                                                '&:hover': {
                                                                    cursor: 'pointer',
                                                                    borderRadius: "32px 0 0 32px",
                                                                }
                                                            }} 
                                                            onClick={() => {
                                                                setOpenInfo(true)
                                                                setInfoEvent(row)
                                                            }}
                                                        >
                                                            
                                                            {row.name} 
                                                            <Box sx={{ marginTop: '1px' }}>
                                                                {
                                                                    row.reminder !== null && (
                                                                        row.reminder.status === 1 && (
                                                                            <AccessAlarmsIcon sx={{ height: 12, width: 12, ml:0.5, color: "green" }} />
                                                                        )
                                                                    )
                                                                }
                                                            </Box>
                                                        </StyledCell>
                                                        <StyledCell align={category.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                            {row.category}
                                                        </StyledCell>
                                                        <StyledCell align={eventDate.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                            {row.eventDate}
                                                        </StyledCell>
                                                        <StyledCell align={createdBy.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                            {row.createdBy}
                                                        </StyledCell>
                                                        <StyledCell align={status.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                            {
                                                                row.status === "Live" 
                                                                ?
                                                                    <>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #4D00FF', background: '#fff', width: "84px" }}>
                                                                                <PlayCircleIcon sx={{ height: 15, width: 15, mr: 1, color: "#FF0065", ml: 1 }} />
                                                                                <span style={{ color: "#4D00FF", fontFamily: "Filson Pro Medium" }}>
                                                                                    {row.status}
                                                                                </span>
                                                                        </Box>
                                                                    </>
                                                                :
                                                                    row.status === "Scheduled" 
                                                                    ?
                                                                        <>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #BBBBBB', background: '#fff', width: "120px"}}>
                                                                                    <WatchLaterIcon sx={{ height: 15, width: 15, mr: 1, color: "#BBBBBB", ml: 1 }} />
                                                                                    <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Thin" }}>
                                                                                        {row.status}
                                                                                    </span>
                                                                            </Box>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #4D00FF', background: '#fff', width: "115px"}}>
                                                                                    <CheckCircleIcon sx={{ height: 15, width: 15, mr: 1, color: "#4D00FF", ml: 1 }} />
                                                                                    <span style={{ color: "#4D00FF", fontFamily: "Filson Pro Light" }}>
                                                                                        {row.status}
                                                                                    </span>
                                                                            </Box>
                                                                        </>
                            
                            
                                                            }
                                                        </StyledCell>
                                                        <StyledCell align={revenue.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Bold", fontSize: fontSize }}>
                                                        {
                                                                row.revenue !== '$0.00' 
                                                                    ? 
                                                                        <>
                                                                            <span style={{ color: "#4D00FF" }}>
                                                                                ${row.revenue}
                                                                            </span>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Regular"}}>
                                                                                ${row.revenue}
                                                                            </span>
                                                                        </>
                                                            }
                                                        </StyledCell>
                                                        <StyledCell align={ticket_sold.align} sx={{  border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Bold", fontSize: fontSize }}>
                                                            {
                                                                row.ticket_sold !== '0' 
                                                                    ? 
                                                                        <>
                                                                            <span style={{ color: "#FF0065", fontFamily: "Filson Pro Bold" }}>
                                                                                {row.ticket_sold}
                                                                            </span>
                                                                            <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Light" }}>
                                                                                /
                                                                            </span>
                                                                            {row.ticket_count}
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Regular"}}>
                                                                                {row.ticket_sold}
                                                                            </span>
                                                                            <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Light" }}>
                                                                                /
                                                                            </span>
                                                                            {row.ticket_count}
                                                                        </>
                                                            }
                                                        </StyledCell>
                                                        <StyledCell align={edit.align} sx={{ borderRadius: "0px 32px 32px 0px", border: "1px solid #C7C7C7", borderLeft: "none", background: '#F7F3FF', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                                            <IconButton sx={{ mt: "1px !important", height: 15, width: 15, }} onClick={() => {
                                                                    setOpen(true)
                                                                    setEditableRow(row)
                                                                    setIndexVal(index)
                                                                    setInitialStatus(row.status)
                                                                    }}
                                                                >
                                                                <EditIcon sx={{ height: 20, width: 20, color: '#7500FF' }} />
                                                            </IconButton>
                                                        </StyledCell>
                                                    </StyledRow>
                                                    <TableRow style={{ height: '5px' }} key={'space-' + index}></TableRow>
                                                    </React.Fragment>
                                                );
                                            })
                                        )
                                    :
                                    dataDisplay.map((row, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                            <StyledRow hover role="checkbox" tabIndex={-1} key={'styled-' + index} >
                                                <StyledCell 
                                                    align={name.align} 
                                                    sx={{ 
                                                        borderRadius: "32px 0 0 32px",
                                                        border: "1px solid #C7C7C7", 
                                                        borderRight: "none", 
                                                        background: '#F7F3FF', 
                                                        fontFamily: "Filson Pro Regular",
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        fontSize: fontSize,
                                                        pt: '23px',
                                                        '&:hover': {
                                                            cursor: 'pointer',
                                                            borderRadius: "32px 0 0 32px",
                                                        }
                                                    }} 
                                                    onClick={() => {
                                                        setOpenInfo(true)
                                                        setInfoEvent(row)
                                                    }}
                                                >
                                                    
                                                    {row.name} 
                                                    <Box sx={{ marginTop: '1px' }}>
                                                        {
                                                            row.reminder !== null && (
                                                                row.reminder.status === 1 && (
                                                                    <AccessAlarmsIcon sx={{ height: 12, width: 12, ml:0.5, color: "green" }} />
                                                                )
                                                            )
                                                        }
                                                    </Box>
                                                </StyledCell>
                                                <StyledCell align={category.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                    {row.category}
                                                </StyledCell>
                                                <StyledCell align={eventDate.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                    {row.eventDate}
                                                </StyledCell>
                                                <StyledCell align={createdBy.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                    {row.createdBy}
                                                </StyledCell>
                                                <StyledCell align={status.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Light", fontSize: fontSize }}>
                                                    {
                                                        row.status === "Live" 
                                                        ?
                                                            <>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #4D00FF', background: '#fff', width: "84px" }}>
                                                                        <PlayCircleIcon sx={{ height: 15, width: 15, mr: 1, color: "#FF0065", ml: 1 }} />
                                                                        <span style={{ color: "#4D00FF", fontFamily: "Filson Pro Medium" }}>
                                                                            {row.status}
                                                                        </span>
                                                                </Box>
                                                            </>
                                                        :
                                                            row.status === "Scheduled" 
                                                            ?
                                                                <>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #BBBBBB', background: '#fff', width: "120px"}}>
                                                                            <WatchLaterIcon sx={{ height: 15, width: 15, mr: 1, color: "#BBBBBB", ml: 1 }} />
                                                                            <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Thin" }}>
                                                                                {row.status}
                                                                            </span>
                                                                    </Box>
                                                                </>
                                                            :
                                                                <>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', borderRadius: "20px", border: '1px solid #4D00FF', background: '#fff', width: "115px"}}>
                                                                            <CheckCircleIcon sx={{ height: 15, width: 15, mr: 1, color: "#4D00FF", ml: 1 }} />
                                                                            <span style={{ color: "#4D00FF", fontFamily: "Filson Pro Light" }}>
                                                                                {row.status}
                                                                            </span>
                                                                    </Box>
                                                                </>
                    
                    
                                                    }
                                                </StyledCell>
                                                <StyledCell align={revenue.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Bold", fontSize: fontSize }}>
                                                {
                                                        row.revenue !== '$0.00' 
                                                            ? 
                                                                <>
                                                                    <span style={{ color: "#4D00FF" }}>
                                                                        ${row.revenue}
                                                                    </span>
                                                                </>
                                                            :
                                                                <>
                                                                    <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Regular"}}>
                                                                        ${row.revenue}
                                                                    </span>
                                                                </>
                                                    }
                                                </StyledCell>
                                                <StyledCell align={ticket_sold.align} sx={{  border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', background: '#F7F3FF', fontFamily: "Filson Pro Bold", fontSize: fontSize }}>
                                                    {
                                                        row.ticket_sold !== '0' 
                                                            ? 
                                                                <>
                                                                    <span style={{ color: "#FF0065", fontFamily: "Filson Pro Bold" }}>
                                                                        {row.ticket_sold}
                                                                    </span>
                                                                    <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Light" }}>
                                                                        /
                                                                    </span>
                                                                    {row.ticket_count}
                                                                </>
                                                            :
                                                                <>
                                                                    <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Regular"}}>
                                                                        {row.ticket_sold}
                                                                    </span>
                                                                    <span style={{ color: "#BBBBBB", fontFamily: "Filson Pro Light" }}>
                                                                        /
                                                                    </span>
                                                                    {row.ticket_count}
                                                                </>
                                                    }
                                                </StyledCell>
                                                <StyledCell align={edit.align} sx={{ borderRadius: "0px 32px 32px 0px", border: "1px solid #C7C7C7", borderLeft: "none", background: '#F7F3FF', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                                    <IconButton sx={{ mt: "1px !important", height: 15, width: 15, }} onClick={() => {
                                                            setOpen(true)
                                                            setEditableRow(row)
                                                            setIndexVal(index)
                                                            setInitialStatus(row.status)
                                                            }}
                                                        >
                                                        <EditIcon sx={{ height: 20, width: 20, color: '#7500FF' }} />
                                                    </IconButton>
                                                </StyledCell>
                                            </StyledRow>
                                            <TableRow style={{ height: '5px' }} key={'space-' + index}></TableRow>
                                            </React.Fragment>
                                        );
                                    }) 
                                } */}
            </TableBody>

            <EditDialog
              openDialog={open}
              openDialogFunc={setOpen}
              editData={dataDisplay[indexVal]}
              indexVal={indexVal}
              initialStatus={initialStatus}
            />
            <InfoDialog
              openDialog={openInfo}
              openDialogFunc={setOpenInfo}
              infoData={infoEvent}
            />
          </Table>
        </TableContainer>
      </Box>

      {loadingMoreData && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: 5,
            mt: 2,
          }}
        >
          <CircularProgress color="secondary" size={20} />
        </Box>
      )}
    </>
  );
};

export default EventTable;
