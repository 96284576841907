import * as React from 'react';

// Material UI Icons
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';

// Material UI component
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Stack, Box, IconButton } from '@mui/material';

const StyledRow = styled(TableRow)(({ theme }) => ({
    '&:hover': {
      backgroundColor: '#F7F3FF',
  
      // Target the TableCell when the TableRow is hovered
      '& .MuiTableCell-root': {
        backgroundColor: '#F7F3FF',
      },
    },
  }));
  
  const StyledCell = styled(TableCell)(({ theme }) => ({
    // Ensuring that by default there's no background or it matches your table's default background
    backgroundColor: 'transparent',
  }));

const columns = [
  { id: 'id', label: "ID", minWidth: 20 },
  { id: 'question', label: 'Question', minWidth: 10 },
  { id: 'type', label: 'Type', minWidth: 10 },
  { id: 'category', label: 'Category', minWidth: 10, align: 'left' },
];

const name = {
    id: 'name',
    label: 'Name',
    minWidth: 17,
    align: 'left',
}

const datepaid = {
    id: 'datepaid',
    label: 'Date Paid',
    minWidth: 17,
    align: 'left',
}

const fundsSet = {
    id: 'fundsSet',
    label: 'Funds Settled',
    minWidth: 20,
    align: 'left',
}

const amount = {
    id: 'amount',
    label: 'Amount',
    minWidth: 20,
    align: 'left',
}

function createData(name, datepaid, fundsSet, amount, status) {
  return { name, datepaid, fundsSet, amount, status };
}

const rows = [
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'Yes', 'ChatId'),
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'Yes', 'ChatId'),
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'No', 'ChatId'),
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'Yes', 'ChatId'),
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'Yes', 'ChatId'),
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'Yes', 'ChatId'),
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'Yes', 'ChatId'),
  createData( 'Brighton v Alta', '09/08/23', '09/08/23','$523', 'Yes', 'ChatId'),
];


const BillPaid = () => {
  const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    return (
      <>
        <TableContainer sx={{ maxHeight: 360 }}>

          <Table stickyHeader aria-label="sticky table" sx={{ width: '98%' }}>

            <TableHead>

              <TableRow>
                <TableCell key={name.id} align={name.align} style={{ minWidth: name.minWidth, backgroundColor: '#F3F1F6', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: '15px' }}>
                    {name.label}
                </TableCell>
                <TableCell key={datepaid.id} align={datepaid.align} style={{ minWidth: datepaid.minWidth, backgroundColor: '#F3F1F6', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: '15px' }}>
                    {datepaid.label}
                </TableCell>
                <TableCell key={fundsSet.id} align={fundsSet.align} style={{ minWidth: fundsSet.minWidth, backgroundColor: '#F3F1F6', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: '15px' }}>
                    {fundsSet.label}
                </TableCell>
                <TableCell key={amount.id} align={amount.align} style={{ minWidth: amount.minWidth, backgroundColor: '#F3F1F6', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: '15px' }}>
                    {amount.label}
                </TableCell>
              </TableRow>

            </TableHead>

            <TableBody>
                {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .flatMap((row, index) => {
                    return (
                        <React.Fragment key={index}>
                        <StyledRow hover role="checkbox" tabIndex={-1} key={'styled-' + index} >
                            
                            <StyledCell align={name.align} sx={{ borderRadius: "32px 0 0 32px", border: "1px solid #C7C7C7", borderRight: "none", fontFamily: "Filson Pro Regular", fontSize: '15px' }}>
                               {
                                row.status === "Yes" 
                                ?
                                    <>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Stack direction="row" spacing={2}>
                                                <CheckCircleIcon sx={{ height: 20, width: 20, color: '#7500FF', mt: '-2px !important' }} />
                                                <span>
                                                    {row.name}
                                                </span>
                                            </Stack>      
                                        </Box>
                                    </>
                                :
                                    row.status === "No"
                                ?
                                    <>
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                    <Stack direction="row" spacing={2}>
                                                        <CancelOutlinedIcon sx={{ height: 20, width: 20, color: '#FF0065', mt: '-2px !important' }} />
                                                        <span>
                                                            {row.name}
                                                        </span>
                                                    </Stack>
                                        </Box>
                                    </>
                                :
                                    console.log("Error in data")
                               }
                            </StyledCell>

                            <StyledCell align={datepaid.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', fontFamily: "Filson Pro Regular", fontSize: '15px' }}>
                                {row.datepaid}
                            </StyledCell>
                            <StyledCell align={fundsSet.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', fontFamily: "Filson Pro Regular", fontSize: '15px' }}>
                                {row.fundsSet}
                            </StyledCell>
                            <StyledCell align={amount.align} sx={{ border: "1px solid #C7C7C7", borderRadius: "0 32px 32px 0", borderRight: "none", borderLeft: 'none', color: '#FF0065', fontFamily: "Filson Pro Bold", fontSize: '15px' }}>
                                {row.amount}
                            </StyledCell>
                        </StyledRow>
                        <TableRow style={{ height: '5px' }} key={'space-' + index}></TableRow>
                        </React.Fragment>
                    );
                })}
            </TableBody>

          </Table>

        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </>
    );
}

export default BillPaid