import React from 'react'
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InputBootstrap from '../inputfields/InputBootstrap';
import ButtonGradient from '../button/ButtonGradient';
import SaveIcon from '@mui/icons-material/Save';
import ProfilePicUpload from '../misc/ProfilePicUpload';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import APP_CONFIG from '../../Const';

const drawerWidth = 400;

const UserDrawers = (props) => {
  const [username, setUsername] = React.useState(Cookies.get('name'));
  const [password, setPassword] = React.useState('');
  const [logoFile, setLogoFile] = React.useState(null);
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }
    props.setState(open);
  };

  const handleChange = async () => {

        if (logoFile !== null) {
            const logo = logoFile[0]
            const formData = new FormData();
            Array.from(logoFile).forEach(file => {
                console.log("Ponch gau andar")
                formData.append('id', Cookies.get('user_id'));
                formData.append('name', username);
                formData.append('email', Cookies.get('email'));
                formData.append('password', password);
                formData.append('avatar', logo)
            })

            console.log(formData)

            try {
                let result = await fetch("https://api.wave.tickets/api/profile-update", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${Cookies.get("token")}`,
                    },
                    body: formData,
                });
                if (result.status === 200) {
                    toast("Credentials updated successfully - Signing Out", { type: ["success"] })
                    setTimeout(() => {
                        axios.get("https://api.wave.tickets/api/auth/logout")
                        .then((response) => {
                            Cookies.remove("token")
                            Cookies.remove("name")
                            Cookies.remove("user_id")
                            Cookies.remove("email")
                            Cookies.remove("avatar")
                            toast(response.data.message, { type: ["success"] })
                            navigate('/')
                        })
                        .catch((err) => {
                            toast("Invalid Token - Logout fail", { type: ["error"] })
                        })
                    }, 1500)
                }
            } catch {
                toast("Network error - check your internet connection", { type: ["error"] })
            }
        } else {
            const item = {
                id: Cookies.get('user_id'),
                name: username,
                email: Cookies.get('email'),
                password: password,
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${Cookies.get("token")}`,
                    Accept: 'application/json'
                }
            };

            axios.post("https://api.wave.tickets/api/profile-update", item, config)
                .then((response) => {
                    toast("Credentials updated successfully - Signing Out", { type: ["success"] })
                    setTimeout(() => {
                        axios.get("https://api.wave.tickets/api/auth/logout")
                        .then((response) => {
                            Cookies.remove("token")
                            Cookies.remove("name")
                            Cookies.remove("user_id")
                            Cookies.remove("email")
                            Cookies.remove("avatar")
                            toast(response.data.message, { type: ["success"] })
                            navigate('/')
                        })
                        .catch((err) => {
                            toast("Invalid Token - Logout fail", { type: ["error"] })
                        })
                    }, 1500)
                })
                .catch((error) => {
                    toast("Network error - check your internet connection", { type: ["error"] })
                })
        }
    }


  const list = () => (

    <Box
        sx={{ width: drawerWidth }}
        role="presentation"
    >

        <Box sx={{
        background: '#F7F7F7',
        height: '50px',
        px: 3,
        py: 3,
        position: 'sticky',
        zIndex: 1,
        top: 0,
        }}>
        
        <Grid container >
            <Grid item xs={2} sx={{ mt: 0.5 }}>
                <Avatar sx={{ bgcolor: '#FF0065' }}>
                    <AssignmentIndIcon sx={{ height: 30, width: 30 }} />
                </Avatar>
            </Grid>
            <Grid item xs={9}>
                <Stack direction="column">
                    <Typography variant="body1" sx={{ fontFamily: 'Filson Pro Bold' }}>Settings</Typography>
                    <Typography variant="body1" sx={{ fontFamily: 'Filson Pro Light' }}>{Cookies.get('name')}</Typography>
                </Stack>
            </Grid>
        </Grid>

        </Box>

        <Box sx={{
        mt: 3,
        px: 14,
        flex: 1,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        }}>
            <ProfilePicUpload logo={setLogoFile} />
        </Box>

        <Box sx={{
            mt: 4,
            px: 4,
            flex: 1,
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
        }}>
            <Stack direction="column" spacing={2}>
                <InputBootstrap label="Name" dataType="String" state={setUsername} defaultValue={Cookies.get('name')} />
                <InputBootstrap label="Change Password" dataType="String" state={setPassword} type="password" />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                    <ButtonGradient text="Save" icon={<SaveIcon />} handleSave={handleChange} />
                </Box>
            </Stack>
            
        </Box>

    </Box>

  );

  return (
    <Drawer
        anchor='right'
        open={props.state}
        onClose={toggleDrawer(false)}
        // onClick={toggleDrawer(false)}
        sx={{
        '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth, 
            borderRadius: '31px 0 0 31px', 
            background: "#FFFFFF",
            boxShadow: '-10px 0px 6px #00000029',
            border: '1px solid #707070',
            },
        }}
    >
        {list()}
    </Drawer>
  )
}

export default UserDrawers