import React from 'react';
import EmojiPicker from 'emoji-picker-react';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie'
import axios from 'axios';

// Material UI Component
import { Box, Button, CircularProgress, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

// Custom component React
import Navbar from '../components/Navigation/Navbar';
import HorizontalNav from '../components/Navigation/HorizontalNav';
import ButtonSwitch from '../components/button/ButtonSwitch';
import FormStack from '../components/form/FormStack';

// Images and logo
import SentimentSatisfiedAltOutlinedIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import ButtonGradient from '../components/button/ButtonGradient';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import face from '../static/emoji/smileyface.png'
import InputBootstrapMulti from '../components/inputfields/InputBootstrapMulti';
import InputBootstrap from '../components/inputfields/InputBootstrap';
import Reminder from '../components/filter/Reminder';
import { toast } from 'react-toastify';
import SendIcon from '@mui/icons-material/Send';
import APP_CONFIG from '../Const';

function selectCloseEvent(data) {
    if (data && data.length > 0) {
        const currentDateTime = new Date();
        let nearestDateTimeDiff = Infinity;
        let nearestDataObject = null;
  
        data.forEach((item) => {
          const itemDateTime = new Date(`${item.datetime}`);
          const timeDifference = Math.abs(currentDateTime - itemDateTime);
  
          if (timeDifference < nearestDateTimeDiff) {
            nearestDateTimeDiff = timeDifference;
            nearestDataObject = item;
          }
        });
        
        return nearestDataObject;
    }
}

function replaceAtWithVs(inputObject) {
    // Check if inputObject is an object and has a 'name' field containing '@'
    if (
      typeof inputObject === 'object' &&
      inputObject !== null &&
      inputObject.hasOwnProperty('name') &&
      typeof inputObject.name === 'string' &&
      inputObject.name.includes('@')
    ) {
      // Create a new object with the modified 'name' field
      let modifiedObject = {
        ...inputObject,
        name: inputObject.name.replace('@', 'vs'),
      };
      return modifiedObject;
    } else {
      // If the conditions are not met, return the original object
      return inputObject;
    }
}
  

const Reminders = ({ activeTab, setActiveTab }) => {

  const [emojiPicker, setEmojiPicker] = React.useState(false);
  const [emojiDisplay, setEmojiDisplay] = React.useState(face);
  const [emojiName, setEmojiName] = React.useState('😀');
  const [eventId, setEventId] = React.useState(0);
  const [nearestData, setNearestData] = React.useState({});
  const [dataLoaded, setDataLoaded] = React.useState(true);
  const [reminderStatus, setReminderStatus] = React.useState(true);
  const [timer, setTimer] = React.useState(4)
  const [number, setNumber] = React.useState("");
  const [text, setText] = React.useState('');

  const isSmallScreen = useMediaQuery('(max-width:950px)')
  const isTablet = useMediaQuery('(max-width:830px)')
  const isMobile = useMediaQuery('(max-width:700px)')
  const navigate = useNavigate();
  const token = Cookies.get("token");

  React.useEffect(() => {
      if (!token) {
        navigate('/')
      }
  }, [])

//   const profileName = 'test';

  React.useEffect(() => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            Accept: 'application/json'
          }
        };
  
        axios.get(`https://api.wave.tickets/api/events?page=${0}`, config)
        .then((response) => {
            const value = selectCloseEvent(response.data.events.data)
            // console.log(value)
            const final_val = replaceAtWithVs(value)
            setTimeout(() => {
                setNearestData(final_val)
                setDataLoaded(false)
                setEventId(value && value.id)
            }, 1500);
        })
      } catch (err) {
        toast("Network Error - Bad Request", { type: ["error"] })
      }
  }, []); // Empty dependency array means this effect runs once when the component mounts


  const positionX = isTablet ? 0 : 10;

  const handleEmoji = (value) => {
    setEmojiDisplay(value.imageUrl)
    setEmojiName(value.emoji)
  }

  const handleSave = () => {

    const item = {
        text: text === '' ? `Huge game tomorrow night ${nearestData && nearestData.name}. Reply "Tickets" to purchase early and skip the line! See you at the game` : text,
        reminder_before: timer.value,
        event_id: eventId,
        status: reminderStatus,
    }

    const config = {
        headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            Accept: 'application/json'
        }
    };

    axios.post("https://api.wave.tickets/api/reminder/store", item, config)
        .then((response) => {
            toast("Reminder created successfully!", { type: ["success"] })
        })
        .catch((error) => {
            toast("Reminder not created successfully!", { type: ["error"] })
        })
  } 

  const handleSendTestMessage = () => {
    // const one = 1
    // const number_USA = one+number
    const item = {
        message: text === '' ? `Huge game tomorrow night ${nearestData && nearestData.name}. Reply "Tickets" to purchase early and skip the line! See you at the game` : text,
        phone_number: number
    }

    const config = {
        headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            Accept: 'application/json'
        }
    };

    axios.post("https://api.wave.tickets/api/send-test-message", item, config)
        .then((response) => {
            toast("Test message sent successfully!", { type: ["success"] })
        })
        .catch((error) => {
            toast("Test message not sent successfully!", { type: ["error"] })
        })

  }

  return (
    <>
        <Navbar />

        <Box sx={{
            background: "radial-gradient(circle at 47.74248758951823% 27.788899739583332%, #5000FF 0%, 38.76%, rgba(80,0,255,0) 68%), radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #5000FF 0%, 13.200000000000001%, rgba(80,0,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(80,0,255,0.99) 0%, 25%, rgba(80,0,255,0) 50%), radial-gradient(circle at 5.416666666666667% 10%, #FF0051 0%, 42%, rgba(255,0,81,0) 70%), radial-gradient(circle at 93.90768686930339% 11.448923746744793%, #7500FF 0%, 42%, rgba(117,0,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)",
            height: '495px',
            pt: 4,  // Adds padding to the top
            px: 5,  // Adds padding to the left and right
            mb: 10
        }}>

            <Box sx={{
                height: 'auto',
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: '1px solid #BCBCBC',
                borderRadius: "20px",
                pt: 5,  // Adds padding to the top
                px: isSmallScreen ? 5 : 7,  // Adds padding to the left and right
                pb: 4,
            }}>

            
                <Grid container sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        {
                            isTablet 
                            ? (
                                <Box sx={{ width: isTablet && 400 }}>
                                    <HorizontalNav activeTab={activeTab} setActiveTab={setActiveTab} />
                                </Box>
                            ) : (
                                <HorizontalNav activeTab={activeTab} setActiveTab={setActiveTab} />
                            )
                        }
                    </Grid>
                </Grid>

                <Box sx={{
                    background: '#F6F5F8BF 0% 0% no-repeat padding-box',
                    borderRadius: '11px',
                    border: '1px solid #e7e7e7',
                    opacity: 1,
                    height: 'auto',
                    pt: 4,  // Adds padding to the top
                    px: 4,  // Adds padding to the left and right
                    mb: 4,
                    pb: 4,
                }}>

                    <Grid container sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={12}>
                            {
                                isTablet ? (
                                    <Stack direction="row" spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="h5" sx={{ color: '#00000', fontFamily: "Alverata Bold Italic", mt: '3px !important' }} >Reminders</Typography>
                                        <ButtonSwitch status={setReminderStatus} />
                                    </Stack>
                                ) : (
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="h5" sx={{ color: '#00000', fontFamily: "Alverata Bold Italic", mt: '3px !important' }} >Reminders</Typography>
                                        <ButtonSwitch status={setReminderStatus} />
                                    </Stack>
                                )
                            }
                        </Grid>
                    </Grid>

                    <Box sx={{
                        pt: 5,  // Adds padding to the top
                        px: positionX,  // Adds padding to the left and right
                    }}>
                        <Grid container spacing={2.5}>
                            <Grid item xs={3} sm={3} md={2} >
                                <Typography sx={{ mt: "10px !important", fontWeight: 700, textAlign: 'right', fontFamily: 'Filson Pro Bold' }}>Pregame:</Typography>
                            </Grid>
                            <Grid item xs={3} sm={3.5} md={2.5}>
                                <Reminder label="Pregame Timer" timer={setTimer} />
                            </Grid>
                            <Grid item xs={5} sm={4.5}>
                                <Typography sx={{
                                    mt: '10px !Important', 
                                    fontStyle: 'italic', 
                                    color: '#000000', 
                                    opacity: 0.44, 
                                    letterSpacing: '0px', 
                                    fontWeight: 300, 
                                    fontSize: 14, 
                                    letterSpacing: "0.5px", 
                                    fontFamily: 'Filson Pro Light',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: `${isMobile && 'none'}`,
                                }}>
                                    Before scheduled start
                                </Typography>
                            </Grid>
                        </Grid>

                        <Box sx={{ mt: 2 }} />

                        <Grid container spacing={2.5}>
                            <Grid item xs={3} sm={3} md={2} >
                                <Typography sx={{ mt: "10px !important", fontWeight: 700, textAlign: 'right', fontFamily: 'Filson Pro Bold' }}>Text:</Typography>
                            </Grid>

                            <Grid item xs={3} sm={3.5} md={2.5}>
                                <Box sx={{ position: 'relative', display: 'inline-block', width: 'fit-content' }}>
        
                                    {dataLoaded ? (
                                        <Box>
                                            <CircularProgress color="secondary" />
                                        </Box>   
                                        ) : (
                                            <>
                                                <InputBootstrapMulti
                                                    label={
                                                        `Huge game tomorrow night ${nearestData && nearestData.name}. Reply "Tickets" to purchase early and skip the line! See you at the game`}
                                                    width={!isTablet ? '425px' : isMobile ? "200px" : "325px"}
                                                    dataType={"String"}
                                                    text={setText}
                                                />

                                                <Box sx={{ mt: 1 }} />
                                                {/* ... (other components) */}
                                            </>
                                        )}

                                    <Box sx={{ mt: 1 }} />

                                    {/* Emoji Picker Trigger */}
                                    <Box sx={{
                                        position: 'absolute',
                                        top: '100%',  // Adjust as necessary for vertical position
                                        right: 0,
                                        height: 25,
                                        width: 25,
                                        borderRadius: '2px',
                                        background: '#fff',
                                        border: '1px solid #70707038',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                        <IconButton sx={{ height: 10, width: 10 }} onClick={() => setEmojiPicker(!emojiPicker)}>
                                            <img src={emojiDisplay} alt="face" style={{ width: '25px' }} />
                                        </IconButton>
                                    </Box>

                                    {/* Emoji Picker */}
                                    <Box sx={{ position: 'absolute', right: 0, zIndex: '1', top: -250 }}>
                                        {
                                            emojiPicker && (
                                                <EmojiPicker onEmojiClick={(e) => handleEmoji(e)} />
                                            )
                                        }
                                    </Box>  

                                </Box>
                            </Grid>
                        </Grid>
                        
                    </Box>

                </Box>

                <Grid container sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }} spacing={3}>
                    <Grid item xs={4.5} sx={{ display: "flex", justifyContent: 'right', alignItems: 'center'}}>
                        <ButtonGradient icon={<SaveOutlinedIcon />} text="Save" handleSave={handleSave} />
                    </Grid>
                    <Grid item xs={7.5} sx={{ display: "flex", justifyContent: 'left', alignItems: 'center'}}>
                        <Stack direction="row" spacing={2}>
                            <Typography sx={{ fontFamily: 'Filson Pro Bold', mt: '10px !important' }}>Send Test:</Typography>
                            <InputBootstrap label="(***) *** ****" state={setNumber} dataType="String" width={'120px'} />
                            <Box
                                sx={{
                                    border: '2px solid #4D00FF',
                                    borderRadius: '10px',
                                    background: 'transparent',
                                    display: 'flex',
                                    p: 1,
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    '&:hover':{
                                        p: 0.9
                                    }
                                }}
                            >
                                <IconButton sx={{ height: '20px', width: '20px' }}>
                                    <SendIcon sx={{ color: '#000' }} onClick={handleSendTestMessage} />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>



            </Box>
        </Box>


    </>
  )
}

export default Reminders