import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Cookies, { set } from "js-cookie";
import { toast } from "react-toastify";
import axios from "axios";
import APP_CONFIG from "../../Const";

function checkEmptyKeys(item) {
  for (const key in item) {
    if (!item[key]) {
      toast(`The key '${key}' is missing, invalid or has an empty value.`, {
        type: ["error"],
      });
      return false;
    }
  }
  return true;
}

const AddUser = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [sinch, setSinch] = React.useState("");

  const handleClose = () => {
    props.openDialogFunc(false);
  };

  const handleSave = () => {
    const item = {
      username: username,
      name: name,
      email: email,
      password: password,
      phone: phone,
      company_name: company,
      sinch_number: sinch,
    };

    if (checkEmptyKeys(item)) {
      const config = {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      };

      axios
        .post(`https://api.wave.tickets/api/admin/users`, item, config)
        .then((response) => {
          toast("User Added Successfully", { type: ["success"] });
          props.openDialogFunc(false);
          setTimeout(() => {
            window.location.reload(true);
          }, 1500);
        })
        .catch((err) => {
          toast("Some fields might be missing.", { type: ["error"] });
          // props.openDialogFunc(false);
        });
    }
  };

  const handleEdit = (event, setState) => {
    setState(event.target.value);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.openDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{ fontFamily: "Filson Pro Bold" }}
      >
        {"Add New User"}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* Username Edit */}
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="Username"
              fullWidth
              variant="standard"
              defaultValue={props.editData && props.editData.username}
              onChange={(event) => handleEdit(event, setUsername)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline.Mui-focused:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInputLabel-root": {
                  color: "#7500FF",
                  fontFamily: "Alverata Md Italic",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "Filson Pro Light",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* No. of email */}
            <TextField
              autoFocus={false}
              margin="dense"
              id="email"
              label="Email"
              fullWidth
              variant="standard"
              // disabled={props.editData && props.editData.ticket_count !== '0' ? true : false}
              onChange={(event) => handleEdit(event, setEmail)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline.Mui-focused:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInputLabel-root": {
                  color: "#7500FF",
                  fontFamily: "Alverata Md Italic",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "Filson Pro Light",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* No. of name */}
            <TextField
              autoFocus={false}
              margin="dense"
              id="name"
              label="Name"
              fullWidth
              variant="standard"
              onChange={(event) => handleEdit(event, setName)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline.Mui-focused:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInputLabel-root": {
                  color: "#7500FF",
                  fontFamily: "Alverata Md Italic",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "Filson Pro Light",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* password */}
            <TextField
              autoFocus={false}
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={(event) => handleEdit(event, setPassword)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline.Mui-focused:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInputLabel-root": {
                  color: "#7500FF",
                  fontFamily: "Alverata Md Italic",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "Filson Pro Light",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* No. of phone */}
            <TextField
              autoFocus={false}
              margin="dense"
              id="phone"
              label="Phone"
              fullWidth
              variant="standard"
              onChange={(event) => handleEdit(event, setPhone)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline.Mui-focused:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInputLabel-root": {
                  color: "#7500FF",
                  fontFamily: "Alverata Md Italic",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "Filson Pro Light",
                },
              }}
            />
          </Grid>

          <Grid item xs={6}>
            {/* Company Name */}
            <TextField
              autoFocus={false}
              margin="dense"
              id="company"
              label="Company Name"
              fullWidth
              variant="standard"
              onChange={(event) => handleEdit(event, setCompany)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline.Mui-focused:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInputLabel-root": {
                  color: "#7500FF",
                  fontFamily: "Alverata Md Italic",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "Filson Pro Light",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {/* No. of Sinch */}
            <TextField
              autoFocus={false}
              margin="dense"
              id="company"
              label="Sinch Number"
              fullWidth
              variant="standard"
              onChange={(event) => handleEdit(event, setCompany)}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInput-underline.Mui-focused:before": {
                  borderBottomColor: "#7500FF",
                },
                "& .MuiInputLabel-root": {
                  color: "#7500FF",
                  fontFamily: "Alverata Md Italic",
                },
                "& .MuiInputBase-root": {
                  fontFamily: "Filson Pro Light",
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose} sx={{ color: "#FF0065" }}>
          Cancel
        </Button>
        <Button onClick={handleSave} autoFocus sx={{ color: "#7500FF" }}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUser;
