// React builtin components imports
import React from 'react';
import { toast } from "react-toastify";
import axios from 'axios'
import Cookies from "js-cookie";

// Custom component React


// Material UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

// Icons
import Logo from '../../static/navigation/waveWhiteNew.png';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import MessageIcon from '@mui/icons-material/Message';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import SupportDrawers from '../drawers/SupportDrawers';
import UserDrawers from '../drawers/UserDrawers';
import ProfilePic from '../../static/emoji/profilePic.png'
import { Link } from '@mui/material';
import APP_CONFIG from '../../Const';

// const pages = ["Dashboard, Billing, Events"]
const settings = ['Settings','Support', 'Logout'];
const icons = [
  <SettingsRoundedIcon fontSize="small" sx={{ color: "#000" }} />,
  <MessageIcon fontSize="small" sx={{ color: "#000" }} />,
  <LogoutIcon fontSize="small" sx={{ color: "#000", rotate: '180deg' }} />
]
const route = [
  '/dashboard',
  '/dashboard',
  '/'
]

const settingsAdmin = ['Settings', 'Logout'];
const iconsAdmin = [
  <SettingsRoundedIcon fontSize="small" sx={{ color: "#000" }} />,
  <LogoutIcon fontSize="small" sx={{ color: "#000", rotate: '180deg' }} />
]
const routeAdmin = [
  '/super-admin',
  '/'
]

function isValidImageUrl(url) {
  // Implement your own validation logic, e.g., check if it's a valid URL or a valid image format
  // For simplicity, let's assume any non-empty string is a valid image link
  return typeof url === 'string' && url.trim() !== '';
}

const Navbar = (props) => {
  const navigate = useNavigate();
  // const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openSupport, setOpenSupport] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);

  const cookieImage = Cookies.get('avatar');
  const isValidImageLink = isValidImageUrl(cookieImage);
  const [profileImage, setProfileImage] = React.useState(isValidImageLink ? cookieImage : ProfilePic);

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (page, index) => {
    if (page === "Logout" & props.admin === "admin"){
      axios.get("https://api.wave.tickets/api/auth/logout")
        .then((response) => {
          Cookies.remove("token")
          Cookies.remove("name")
          Cookies.remove("user_id")
          Cookies.remove("email")
          Cookies.remove("avatar")
          Cookies.remove("is_admin")
          toast(response.data.message, { type: ["success"] })
          navigate(routeAdmin[index])
        })
    }
    else if (page === "Logout"){
      axios.get("https://api.wave.tickets/api/auth/logout")
      .then((response) => {
        Cookies.remove("token")
        Cookies.remove("name")
        Cookies.remove("user_id")
        Cookies.remove("email")
        Cookies.remove("avatar")
        toast(response.data.message, { type: ["success"] })
        navigate(route[index])
      })
      .catch((err) => {
        toast("Invalid Token - Logout fail", { type: ["error"] })
      })
    }
    else if (page === "Support"){
      setOpenSupport(true)
    }
    else if (page === "Settings"){
      setOpenUser(true)
    }
    handleCloseUserMenu(); // Close the menu after clicking a menu item
  };

  return (
    <AppBar position="static" sx={{ background: 'transparent linear-gradient(285deg, #7500FF 0%, #FF0065 170%) 0% 0% no-repeat padding-box', boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          {/* Logo Web */}
          <Link href="https://wave.tickets" target="_blank" rel="noopener noreferrer">
          <Box
              component="img"
              sx={{
                  height: "40px",
                  display: { xs: 'flex', color: '#000' },
              }}
              alt="Wavelogo"
              src={Logo}
          />
          </Link>
          
          {/* Nav Menu */}
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {/* {settings.map((page) => (
              <Button
                key={page}
                onClick={handleCloseUserMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))} */}
          </Box>

          <Box sx={{ 
             flexGrow: 0,
             borderRadius: '50%',
             height: '50px',
             width: '50px',
             position: 'relative', // Set position to relative
             overflow: 'hidden',
             background: '#fff',
             border: '2px solid #fff'
            }}
          >
            <img
              src={profileImage}
              alt="User Avatar"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '50%',
                zIndex: 1, // Set zIndex to bring the image to the background
              }}
            />
            
            <Tooltip title="User Nav">
              <IconButton onClick={handleOpenUserMenu} sx={{ 
                  position: 'absolute',
                  height: 60,
                  width: 60,
                  top: 0,
                  left: 0,
                  zIndex: 2, // Set a higher zIndex to bring the IconButton to the front
                }} 
              />
            </Tooltip>
            
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {}
              {
                props.admin === "admin" ? (

                  settingsAdmin.map((setting, index) => (
                    <MenuItem key={setting} onClick={() => handleMenuItemClick(setting, index)}>
                      <ListItemIcon>
                        {iconsAdmin[index]}
                      </ListItemIcon>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))

                ) : (

                  settings.map((setting, index) => (
                    <MenuItem key={setting} onClick={() => handleMenuItemClick(setting, index)}>
                      <ListItemIcon>
                        {icons[index]}
                      </ListItemIcon>
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))
                  
                )
              }
            </Menu>
          </Box>

          <SupportDrawers state={openSupport} setState={setOpenSupport} />
          <UserDrawers state={openUser} setState={setOpenUser} />

        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Navbar