import React from 'react';

// Custom component React

// Material UI Component
import { Button } from '@mui/material';

// Images and logo
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

const ButtonGradient = (props) => {
  return (
    <Button
    onClick={props.handleSave}
    startIcon={props.icon}
    sx={{
        color: '#fff',
        height: '48px',
        width: '150px',
        background: 'transparent linear-gradient(90deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
        border: '1px solid #00000000',
        opacity: 1,
        borderRadius: "14px",
        textTransform: 'none',
        fontSize: '20px',
        fontFamily: 'Filson Pro Bold',
        "&:hover": {
            color: '#fff',
            background: 'transparent linear-gradient(90deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
            border: '2px solid #FFFFFF',
            borderRadius: "14px",
        }
    }}>
        {props.text}
    </Button>
  )
}

export default ButtonGradient