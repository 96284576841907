import * as React from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';

// Material UI Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Material UI component
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { Stack, Box, useMediaQuery, IconButton } from '@mui/material';
import AddUser from '../dialogs/AddUser';
import EditUser from '../dialogs/EditUser';
import DeleteUser from '../dialogs/DeleteUser';
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import APP_CONFIG from '../../Const';

const StyledRow = styled(TableRow)(() => ({
  '&:hover': {
    backgroundColor: '#F7F3FF',

    // Target the TableCell when the TableRow is hovered
    '& .MuiTableCell-root': {
      backgroundColor: '#F7F3FF',
    },
  },
}));
  
  const StyledCell = styled(TableCell)(() => ({
    // Ensuring that by default there's no background or it matches your table's default background
    backgroundColor: 'transparent',
  }));

const columns = [
  { id: 'id', label: "ID", minWidth: 20 },
  { id: 'question', label: 'Question', minWidth: 10 },
  { id: 'type', label: 'Type', minWidth: 10 },
  { id: 'category', label: 'Category', minWidth: 10, align: 'left' },
];

const userId = {
    id: 'user_id',
    label: 'User ID',
    minWidth: 17,
    align: 'center',
}

const username = {
    id: 'username',
    label: 'Username',
    minWidth: 17,
    align: 'left',
}

const email = {
    id: 'email',
    label: 'Email',
    minWidth: 17,
    align: 'left',
}

const name = {
    id: 'name',
    label: 'Name',
    minWidth: 20,
    align: 'left',
}

const companyName = {
    id: 'companyName',
    label: 'Company name',
    minWidth: 20,
    align: 'left',
}

const phone = {
    id: 'phone',
    label: 'Phone',
    minWidth: 20,
    align: 'left',
}

const edit = {
    id: 'edit',
    label: 'Edit',
    minWidth: 20,
    align: 'left',
}

const del = {
  id: 'del',
  label: 'Delete',
  minWidth: 20,
  align: 'left',
}

function createData(userId, username, email, name, phone, companyName, sinchNumber, del, edit, ) {
  return { userId, username, email, name, phone, companyName, sinchNumber, del, edit  };
}


const AdminList = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const isMedium = useMediaQuery('(max-width:1000px)');
    const isSmall = useMediaQuery('(max-width:800px)');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogDel, setOpenDialogDel] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [dataDisplay, setDataDisplay] = React.useState([])
    const [indexVal, setIndexVal] = React.useState('')

    const fontSize = !isMedium ? '15px' : isSmall ? '10px' : '12px';

    React.useEffect(() => {

        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
                Accept: 'application/json'
            }
        };

        axios.get(`https://api.wave.tickets/api/admin/users`, config)
            .then((response) => {
                console.log(response)
                const datamap = response.data.users.map((item) => {
                    return createData(
                        item.id,
                        item.username,
                        item.email,
                        item.name,
                        item.phone,
                        item.company_name,
                        item.sinch_number
                    )
                });
                setDataDisplay(datamap)
                setIsLoading(false)
            })
            .catch((error) => {
                toast("Network Error - Bad Request", { type: ["error"] });
            })
    }, [])
  
    return (
        isLoading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <CircularProgress color="secondary" />
            </Box>
        ) : (
            <>
                <TableContainer sx={{ 
                        maxHeight: 360, 
                        '&::-webkit-scrollbar': {
                            display: 'none'
                        },
                        msOverflowStyle: 'none'
                    }}
                >

                <Table stickyHeader aria-label="sticky table" sx={{ width: '98%' }}>

                    <TableHead>
                    <TableRow>
                        <TableCell key={userId.id} align={userId.align} style={{ minWidth: userId.minWidth, backgroundColor: '#fff', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {userId.label}
                        </TableCell>
                        <TableCell key={username.id} align={username.align} style={{ minWidth: username.minWidth, backgroundColor: '#fff', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {username.label}
                        </TableCell>
                        <TableCell key={email.id} align={email.align} style={{ minWidth: email.minWidth, backgroundColor: '#fff', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {email.label}
                        </TableCell>
                        <TableCell key={name.id} align={name.align} style={{ minWidth: name.minWidth, backgroundColor: '#fff', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {name.label}
                        </TableCell>
                        <TableCell key={phone.id} align={phone.align} style={{ minWidth: phone.minWidth, backgroundColor: '#fff', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {phone.label}
                        </TableCell>
                        <TableCell key={companyName.id} align={companyName.align} style={{ minWidth: companyName.minWidth, backgroundColor: '#fff', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {companyName.label}
                        </TableCell>
                        <TableCell key={edit.id} align={edit.align} style={{ minWidth: edit.minWidth, backgroundColor: '#fff', }} sx={{ borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {edit.label}
                        </TableCell>
                        <TableCell key={del.id} align={del.align} style={{ minWidth: del.minWidth, backgroundColor: '#fff', }} sx={{ borderRadius: "0 32px 32px 0", borderBottom: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                            {del.label}
                        </TableCell>
                    </TableRow>
                    </TableHead>

                    <TableBody>
                        {dataDisplay.map((row, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <StyledRow hover role="checkbox" tabIndex={-1} key={'styled-' + index} >
                                        <StyledCell align={userId.align} sx={{ borderRadius: "32px 0 0 32px", border: "1px solid #C7C7C7", borderRight: "none", fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                            {row.userId}
                                        </StyledCell> 
                                        <StyledCell align={username.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                        {row.username === "" ? '-' : row.username}
                                        </StyledCell>
                                        <StyledCell align={email.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                            {row.email === "" ? '-' : row.email}
                                        </StyledCell>
                                        <StyledCell align={name.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                            {row.name === "" ? '-' : row.name}
                                        </StyledCell>
                                        <StyledCell align={phone.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                            {row.phone === "" ? '-' : row.phone}
                                        </StyledCell>
                                        <StyledCell align={companyName.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', fontFamily: "Filson Pro Regular", fontSize: fontSize }}>
                                            {row.companyName === undefined ? '-' : row.companyName}
                                        </StyledCell>

                                        <StyledCell align={edit.align} sx={{ border: "1px solid #C7C7C7", borderRight: "none", borderLeft: 'none', color: '#7500FF', fontFamily: "Filson Pro Bold", fontSize: fontSize }}>
                                            <IconButton onClick={() => {
                                                setOpenDialog(true)
                                                setIndexVal(index)
                                            }}>
                                                <EditOutlinedIcon sx={{ color: '#7500FF' }} />
                                            </IconButton>
                                        </StyledCell>
                                        <StyledCell align={del.align} sx={{ border: "1px solid #C7C7C7", borderRadius: "0 32px 32px 0", borderRight: "none", borderLeft: 'none',  fontFamily: "Filson Pro REgular", fontSize: fontSize }}>
                                        <IconButton onClick={() => {
                                                setOpenDialogDel(true)
                                                setIndexVal(index)
                                            }}>
                                                <DeleteOutlineOutlinedIcon sx={{ color: '#7500FF' }} />
                                            </IconButton>
                                        </StyledCell>

                                    </StyledRow>
                                    <TableRow style={{ height: '5px' }} key={'space-' + index}></TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>

                    <EditUser openDialog={openDialog} openDialogFunc={setOpenDialog} editData={dataDisplay[indexVal]} />
                    <DeleteUser openDialog={openDialogDel} openDialogFunc={setOpenDialogDel} editData={dataDisplay[indexVal]} />

                </Table>

                </TableContainer>
            </>
        )
    );
}

export default AdminList