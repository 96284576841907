import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Grid, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Cookies, { set } from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';
import APP_CONFIG from '../../Const';

const DeleteUser = (props) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const statusForm = ['Live', 'Scheduled', 'Complete'];
    const [selectedStatusMap, setSelectedStatusMap] = React.useState({});
    const [name, setName] = React.useState('');
    const [noTickets, setNoTickets] = React.useState('');
    const [eventDate, setEventDate] = React.useState('');
    const [category, setCategory] = React.useState('');

    const handleClose = () => {
      props.openDialogFunc(false);
    };

    const handleSave = () => {

      const config = {
          headers: {
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("token")}`,
          }
      }

      axios.delete(`https://api.wave.tickets/api/admin/users/${props.editData && props.editData.userId}`, config)
        .then((response) => {
            toast("User Deleted Successfully", {type: ["success"]})
            props.openDialogFunc(false);
            setTimeout(() => {
                window.location.reload(true)
              }, 1500);
        })
        .catch(err => {
            toast("Network error", {type: ["error"]})
            props.openDialogFunc(false);
        })
    }

    const handleEdit = (event, setState) => {
        setState(event.target.value)
    }
  
    return (
        <Dialog
          fullScreen={fullScreen}
          open={props.openDialog}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >

          <DialogTitle id="responsive-dialog-title" sx={{ fontFamily: 'Filson Pro Bold' }}>
            {"Delete User"}
          </DialogTitle>

          <DialogContent>
            <Typography sx={{ fontFamily: 'Filson Pro Regular' }}>Are you sure you want to delete this user?</Typography>
          </DialogContent>

          <DialogActions>
            <Button autoFocus onClick={handleClose} sx={{ color: '#FF0065' }}>
              Cancel
            </Button>
            <Button onClick={handleSave} autoFocus sx={{ color: '#7500FF' }}>
              Delete
            </Button>
          </DialogActions>

        </Dialog>
    );
}

export default DeleteUser