import React, { useEffect, useMemo, useState } from "react";
import { Area } from "@ant-design/plots";
import axios from "axios";

// Custom component React

// Material UI Component
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import { format, getMonth } from "date-fns";

// Images and logo

const months = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const Areachart = (props) => {
  const [revenue, setRevenue] = useState([]);

  const getRevenue = async (config) => {
    try {
      const { data } = await axios.get(
        "https://api.wave.tickets/api/revenue",
        config
      );
      const formattedData = data.map((item, index) => {
        return {
          date: months[item.month],
          revenue: item.revenue,
        };
      });
      setRevenue(formattedData);
    } catch (err) {
      throw new Error(err);
    }
  };

  const config = useMemo(
    () => ({
      data: revenue,
      xField: "date",
      yField: "revenue",
      smooth: true,
      xAxis: {
        range: [0, 1],
        tickCount: 6,
        grid: {
          line: {
            style: {
              stroke: "#fff", // Change this to the desired color
              lineWidth: 1,
            },
          },
        },
      },
      yAxis: {
        visible: false,
        grid: {
          line: {
            style: {
              stroke: "#fff", // Change this to the desired color
              lineWidth: 1,
            },
          },
        },
      },
      areaStyle: () => {
        return {
          fill: "l(270) 0:#ffffff 0.5:#ac66ff 1:#7500FF",
        };
      },
      line: {
        style: {
          stroke: "#7500FF", // This will change the main line color to red
          lineWidth: 2,
        },
      },
    }),
    [revenue]
  );

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        Accept: "application/json",
      },
    };
    getRevenue(config);
  }, []);

  return (
    <div style={{ height: "118px" }}>
      <Area {...config} />
    </div>
  );
};

export default Areachart;
