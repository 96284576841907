import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Stack, Grid, InputBase, FormControl, Button, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import Upload from '../misc/Upload';
import ButtonGradient from '../button/ButtonGradient'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import mobile from '../../static/events/MockPhone.png'
import ticket from '../../static/events/QRcode.png'
import { SketchPicker } from 'react-color';
import Cookies from 'js-cookie';
import axios from 'axios';
import { toast } from 'react-toastify';
import CancelIcon from '@mui/icons-material/Cancel';
import DatePick from '../datetimepicker/DatePick';
import InputBootstrap from '../inputfields/InputBootstrap';
import dayjs from 'dayjs';
import UploadFile from '../misc/UploadFile';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import RefreshIcon from '@mui/icons-material/Refresh';
import logo_random from '../../static/emoji/profilePic.webp'
import APP_CONFIG from '../../Const';
import { useNavigate } from 'react-router';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  const bgColor = value === index ? "#F3F1F6" : "transparent";

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, backgroundColor: bgColor }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
  
CustomTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
  };
}

function checkEmptyKeys(item) {
  for (const key in item) {
    if (!item[key]) {
      toast(`The key '${key}' is missing, invalid or has an empty value.`, {type : ["error"]});
      return false;
    }
  }
  return true;
}

function isValidImageUrl(url) {
  return typeof url === 'string' && url.trim() !== '';
}

const NavEventStyles = () => {
  const [value, setValue] = React.useState(0);
  const [firstColor, setFirstColor] = React.useState(Cookies.get("firstColor") || '#FF0065');
  const [secondColor, setSecondColor] = React.useState(Cookies.get("SecondColor") || '#7500FF');
  const [isColorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [isSecondColorPickerOpen, setSecondColorPickerOpen] = React.useState(false);
  const [eventTitle, setEventTitle] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [tickets, setTickets] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [dateValue, SetDateValue] = React.useState('');
  const [file, setFile] = React.useState(null);
  // const [logo, setLogo] = React.useState(null);
  const cookieImage = Cookies.get('avatar');
  const isValidImageLink = isValidImageUrl(cookieImage);
  const [logoDisplay, setLogoDisplay] = React.useState(isValidImageLink ? cookieImage : logo_random);

  const isMediumScreen = useMediaQuery('(max-width:1100px)');
  const isMediumLaptop = useMediaQuery('(max-width:1240px)');
  const isMediumTablet = useMediaQuery('(max-width:900px)');

  const bulkPadding = isMediumLaptop ? 0 : 20;
  const navigate = useNavigate();

  const downloadTemplate = () => {
    window.location.href = APP_CONFIG.API_URL+"/event_sample.csv";
  };

  const handleFileUpload = (file) => {
    setFile(file)
  }

  // const handleLogoUpload = (logoFile) => {
  //   setLogo(logoFile)
  // }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleColorPickerToggle = () => {
    setColorPickerOpen(true);
  };

  const handleFirstColorClosed = () => {
    setColorPickerOpen(false)
  }

  const handleSecondColorPickerToggle = () => {
    setSecondColorPickerOpen(true);
  };

  const handleSecondColorClosed = () => {
    setSecondColorPickerOpen(false)
  }

  const firstButtonStyle = {
    boxShadow: 'none',
    backgroundColor: firstColor, // Use selectedColor here
    mt: -0.5,
    height: '38px',
    width: `${ isMediumLaptop && '40px' }`,
    padding: '0',        // Override padding
    minWidth: `${ isMediumLaptop && '0' }`,
    '&:hover': {
      backgroundColor: firstColor, // Use selectedColor here
    },
  };

  const secondButtonStyle = {
    boxShadow: 'none',
    backgroundColor: secondColor, // Use selectedColor here
    mt: -0.5,
    height: '38px',
    width: `${ isMediumLaptop && '40px' }`,
    padding: '0',        // Override padding
    minWidth: `${ isMediumLaptop && '0' }`,
    '&:hover': {
      backgroundColor: secondColor, // Use selectedColor here
    },
  };

  const handleSave = async (tab) => {

    if (tab === 0) {
      const date = dayjs(dateValue)
      if (isNaN(date.$y)) {
        toast('Please select a date', { type: ["error"] })
      // } else if(logo === null){
      //   toast('Please upload an image', { type: ["error"] })
      } else {
        const originalDate = new Date(date.$d)
        // const year = originalDate.getFullYear()
        // const month = (originalDate.getMonth() + 1).toString().padStart(2, '0')
        // const day = originalDate.getDate().toString().padStart(2, '0')
        // const dateString = `${year}-${month}-${day}`
        // const hours = originalDate.getHours().toString().padStart(2, '0')
        // const minutes = originalDate.getMinutes().toString().padStart(2, '0')
        // const seconds = originalDate.getSeconds().toString().padStart(2, '0')
        // const timeString = `${hours}:${minutes}:${seconds}`
        const eventName = eventTitle + ' @ ' + Cookies.get('name')
        const time = date.unix()
        // const logoFile = logo[0];

        // console.log(date)

        const item = {
          datetime: time,
          // time: timeString,
          // venue: location,
          ticket_price: price,
          name: eventName,
          category: category,
          cat: 'cat',
          revenue: tickets*price,
          total_tickets: tickets,
        };

        // const formData = new FormData();
        // Array.from(logo).forEach(file => {
        //   formData.append('date', dateString);
        //   formData.append('time', timeString);
        //   formData.append('venue', location);
        //   formData.append('ticket_price', price);
        //   formData.append('name', eventTitle);
        //   formData.append('category', category);
        //   formData.append('cat', 'cat');
        //   formData.append('revenue', price*tickets);
        //   formData.append('total_tickets', tickets);
        //   // formData.append('event_image', logoFile);
        // })

        try {
          if (checkEmptyKeys(item)) {

            // let result = await fetch("https://api.wave.tickets/api/events", {
            //   method: "POST",
            //   headers: {
            //     Accept: "application/json",
            //     Authorization: `Bearer ${Cookies.get("token")}`,
            //   },
            //   body: JSON.stringify(item),
            // });
    
            // if (result.status === 200) {
            //   toast("Event Added Successfully", {type: ["success"]})
            // } else if (result.status === 413) {
            //   toast("File size is too large", { type: ["error"] })
            // } else {
            //   toast("Network Error - Please check your internet.", { type: ["error"] })
            // }

            const config = {
              headers: {
                  Authorization: `Bearer ${Cookies.get("token")}`,
                  Accept: 'application/json'
              }
            };

            axios.post("https://api.wave.tickets/api/events", item, config)
              .then((response) => {
                toast("Event submitted successfully", { type: ["success"] })
                setTimeout(() => {
                  navigate('/reminders')
                }, 500);
              })
              .catch((error) => {
                toast("Network error - check your internet connection", { type: ["error"] })
              })
          }
        }
        catch {
          toast("Network error - check your internet connection", { type: ["error"] })
        }
      }
    } else if(tab === 1){
      if (file === null){
        toast('Please upload a event file', { type: ["error"] })
      } else {

        const csvFile = file[0];
        const formData = new FormData();
        Array.from(file).forEach(file => {
          formData.append('events', csvFile);
        })

        try {

          let result = await fetch("https://api.wave.tickets/api/events/upload-csv", {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
            body: formData,
          });

          if (result.status === 200) {
            toast("Event Added Successfully", {type: ["success"]})
            setTimeout(() => {
              window.location.reload(true)
            }, 1000);
          } else {
            toast("File can not be sent. Check your internet connection", { type: ["error"] })
          }

        } catch {
          toast("File size error or check your internet connection", { type: ["error"] })
        }

      }
    }
    


  }

  const refreshWindow = () => {
    window.location.reload(true)
  }

  const RenderMobileLarge = ({ ticket, eventTitle, location, dateValue, tickets, logoDisplay, firstColor, secondColor, mobile }) => {
    return (

      <React.Fragment>
        <img
          src={ticket}
          alt="Mobile Image"
          style={{
            position: 'absolute',
            top: 225,
            right: 73,
            zIndex: 5,
            width: '210px', // Set the width to your desired size
            height: 'auto',
            pointerEvents: 'none', // Make the image non-interactable
            borderRadius: '20px',
          }}
        />

        <Box 
          sx={{
              background: 'transparent',
              position: 'absolute',
              top: 435,
              right: 68,
              zIndex: 5,
              width: '220px', // Set the width to your desired size
              height: '58px',
              pointerEvents: 'none', // Make the image non-interactable
              boxShadow: 'none',
          }}
        >
          <Stack direction="column" spacing={0.8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1  }}>
            {
              (eventTitle !== '') && (
                <Typography variant='body2' sx={{ color: '#000', fontWeight: 500, fontFamily: 'Filson Pro Regular' }}>{eventTitle} @ {Cookies.get('name')}</Typography>
              )
            }
            
            {
              dateValue !== "" && (
                <Typography variant='body2' sx={{ color: '#000', fontWeight: 500, fontFamily: 'Filson Pro Regular' }}>{dateValue.$H}:{dateValue.$m} PM &nbsp;
                  <span style={{ color: "#abababa" }}>
                  {dateValue.$M}/{dateValue.$D}/{dateValue.$y}  
                  </span>
                </Typography>
              )
            }
            
          </Stack>
        </Box> 

        {/* <Box sx={{
              background: '#BD10E0',
              position: 'absolute',
              top: 130,
              right: 150,
              zIndex: 4,
              width: '50px', // Set the width to your desired size
              height: '50px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '50%',
              boxShadow: 'none',
              border: '4px solid #fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
          }}>

            <Typography variant="" sx={{ fontSize: "20px", fontFamily: "Filson Pro Bold", color: "#fff" }}> {tickets !== '' ? tickets : 0} </Typography>

        </Box>   */}

        <Box sx={{
              background: `#BD10E0`,
              backgroundImage: `url(${[logoDisplay]})`,
              backgroundSize: 'cover', // Fit the background image within the circle
              backgroundPosition: 'center center',
              position: 'absolute',
              // top: 510,
              top: 130,
              right: 150,
              zIndex: 4,
              width: '50px', // Set the width to your desired size
              height: '50px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '50%',
              boxShadow: 'none',
              border: '4px solid #fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
          }} 
        />

        <Box 
          sx={{
              background: '#fff',
              position: 'absolute',
              top: 210,
              right: 65,
              zIndex: 4,
              width: '220px', // Set the width to your desired size
              height: '280px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '18px 18px 18px 18px',
              boxShadow: 'none',
              border: '2px solid #8b889b'
          }}
        /> 

        <Box 
          sx={{
              background: '#fff',
              position: 'absolute',
              top: 35.5,
              right: 102,
              zIndex: 3,
              width: '147px', // Set the width to your desired size
              height: '22px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '0px 0px 18px 18px',
              boxShadow: 'none'
          }}
        />

        <Box 
          sx={{
              background: '#F3F1F6',
              position: 'absolute',
              top: 160,
              right: 45,
              zIndex: 3,
              width: '262px', // Set the width to your desired size
              height: '380px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '18px 18px 18px 18px',
              boxShadow: 'none'
          }}
        />

        <Box 
          sx={{
              background: `linear-gradient(to bottom, ${firstColor}, ${secondColor})`,
              position: 'absolute',
              top: 35.5,
              right: 30,
              zIndex: 2,
              width: '292px', // Set the width to your desired size
              height: '630px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '28px',
          }}
        /> 

        <img
          src={mobile}
          alt="Mobile Image"
          style={{
            position: 'absolute',
            top: -25,
            right: -121,
            zIndex: 1,
            width: '600px', // Set the width to your desired size
            height: 'auto',
            pointerEvents: 'none', // Make the image non-interactable
          }}
        />

      </React.Fragment>
    )
  }

  const RenderMobileMedium = ({ ticket, eventTitle, location, dateValue, tickets, logoDisplay, firstColor, secondColor, mobile }) => {
    return (

      <React.Fragment>
        <img
          src={ticket}
          alt="Mobile Image"
          style={{
            position: 'absolute',
            top: 265,
            right: 45,
            zIndex: 5,
            width: '125px', // Set the width to your desired size
            height: 'auto',
            pointerEvents: 'none', // Make the image non-interactable
            borderRadius: '20px',
          }}
        />

        <Box 
          sx={{
              background: 'transparent',
              position: 'absolute',
              top: 395,
              right: -4,
              zIndex: 5,
              width: '220px', // Set the width to your desired size
              height: '58px',
              pointerEvents: 'none', // Make the image non-interactable
              boxShadow: 'none',
          }}
        >
          <Stack direction="column" spacing={0.8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1  }}>
            {
              (eventTitle !== '') && (
                <Typography variant='body2' sx={{ color: '#000', fontWeight: 500, fontFamily: 'Filson Pro Regular', fontSize: "10px" }}>{eventTitle} @ {Cookies.get('name')}</Typography>
              )
            }
            
            {
              dateValue !== "" && (
                <Typography variant='body2' sx={{ color: '#000', fontWeight: 500, fontFamily: 'Filson Pro Regular', fontSize: "10px" }}>{dateValue.$H}:{dateValue.$m} PM &nbsp;
                  <span style={{ color: "#abababa" }}>
                  {dateValue.$M}/{dateValue.$D}/{dateValue.$y}  
                  </span>
                </Typography>
              )
            }
            
          </Stack>
        </Box>

        <Box sx={{
              background: '#BD10E0',
              position: 'absolute',
              top: 195,
              right: 85,
              zIndex: 4,
              width: '35px', // Set the width to your desired size
              height: '35px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '50%',
              boxShadow: 'none',
              border: '4px solid #fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
          }}>

            <Typography variant="" sx={{ fontSize: "20px", fontFamily: "Filson Pro Bold", color: "#fff" }}> {tickets !== '' ? tickets : 0} </Typography>

        </Box>

        <Box sx={{
              background: `#BD10E0`,
              backgroundImage: `url(${[logoDisplay]})`,
              backgroundSize: 'cover', // Fit the background image within the circle
              backgroundPosition: 'center center',
              position: 'absolute',
              top: 460,
              right: 85,
              zIndex: 4,
              width: '35px', // Set the width to your desired size
              height: '35px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '50%',
              boxShadow: 'none',
              border: '4px solid #fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
          }} />

        <Box 
          sx={{
              background: '#fff',
              position: 'absolute',
              top: 250,
              right: 38,
              zIndex: 4,
              width: '135px', // Set the width to your desired size
              height: '200px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '18px 18px 18px 18px',
              boxShadow: 'none',
              border: '2px solid #8b889b'
          }}
        />

        <Box 
          sx={{
              background: '#fff',
              position: 'absolute',
              top: 140.5,
              right: 45,
              zIndex: 3,
              width: '120px', // Set the width to your desired size
              height: '18px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '0px 0px 18px 18px',
              boxShadow: 'none'
          }}
        />

        <Box 
          sx={{
              background: '#F3F1F6',
              position: 'absolute',
              top: 220,
              right: 20,
              zIndex: 3,
              width: '175px', // Set the width to your desired size
              height: '262px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '18px 18px 18px 18px',
              boxShadow: 'none'
          }}
        />

        <Box 
          sx={{
              background: `linear-gradient(to bottom, ${firstColor}, ${secondColor})`,
              position: 'absolute',
              top: 140,
              right: 10,
              zIndex: 2,
              width: '194px', // Set the width to your desired size
              height: '420px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '20px',
          }}
        />

        <img
          src={mobile}
          alt="Mobile Image"
          style={{
            position: 'absolute',
            top: 100,
            right: -90,
            zIndex: 1,
            width: '400px', // Set the width to your desired size
            height: 'auto',
            pointerEvents: 'none', // Make the image non-interactable
          }}
        />

      </React.Fragment>
    )
  }

  const RenderMobileSmall = ({ ticket, eventTitle, location, dateValue, tickets, logoDisplay, firstColor, secondColor, mobile }) => {
    return (

      <React.Fragment>
        <img
          src={ticket}
          alt="Mobile Image"
          style={{
            position: 'absolute',
            top: 180,
            right: 32,
            zIndex: 5,
            width: '80px', // Set the width to your desired size
            height: 'auto',
            pointerEvents: 'none', // Make the image non-interactable
            borderRadius: '20px',
          }}
        />

        <Box 
          sx={{
              background: 'transparent',
              position: 'absolute',
              top: 270,
              right: -37,
              zIndex: 5,
              width: '220px', // Set the width to your desired size
              height: '58px',
              pointerEvents: 'none', // Make the image non-interactable
              boxShadow: 'none',
          }}
        >
          <Stack direction="column" spacing={0.8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1  }}>
            {
              (eventTitle !== '') && (
                <Typography variant='body2' sx={{ color: '#000', fontWeight: 500, fontFamily: 'Filson Pro Regular', fontSize: "7px" }}>{eventTitle} @ {Cookies.get('name')}</Typography>
              )
            }
            
            {
              dateValue !== "" && (
                <Typography variant='body2' sx={{ color: '#000', fontWeight: 500, fontFamily: 'Filson Pro Regular', fontSize: "7px" }}>{dateValue.$H}:{dateValue.$m} PM &nbsp;
                  <span style={{ color: "#abababa" }}>
                  {dateValue.$M}/{dateValue.$D}/{dateValue.$y}  
                  </span>
                </Typography>
              )
            }
            
          </Stack>
        </Box>

        <Box sx={{
              background: '#BD10E0',
              position: 'absolute',
              top: 132,
              right: 56,
              zIndex: 4,
              width: '25px', // Set the width to your desired size
              height: '25px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '50%',
              boxShadow: 'none',
              border: '4px solid #fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
          }}>

            <Typography variant="" sx={{ fontSize: "10px", fontFamily: "Filson Pro Bold", color: "#fff" }}> {tickets !== '' ? tickets : 0} </Typography>

        </Box>

        <Box sx={{
              background: `#BD10E0`,
              backgroundImage: `url(${[logoDisplay]})`,
              backgroundSize: 'cover', // Fit the background image within the circle
              backgroundPosition: 'center center',
              position: 'absolute',
              top: 330,
              right: 56,
              zIndex: 4,
              width: '25px', // Set the width to your desired size
              height: '25px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '50%',
              boxShadow: 'none',
              border: '4px solid #fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignContent: 'center',
          }} />

        <Box 
          sx={{
              background: '#fff',
              position: 'absolute',
              top: 170,
              right: 26.5,
              zIndex: 4,
              width: '90px', // Set the width to your desired size
              height: '150px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '18px 18px 18px 18px',
              boxShadow: 'none',
              border: '2px solid #8b889b'
          }}
        />

        <Box 
          sx={{
              background: '#fff',
              position: 'absolute',
              top: 104,
              right: 38,
              zIndex: 3,
              width: '70px', // Set the width to your desired size
              height: '12px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '0px 0px 18px 18px',
              boxShadow: 'none'
          }}
        /> 

        <Box 
          sx={{
              background: '#F3F1F6',
              position: 'absolute',
              top: 150,
              right: 20,
              zIndex: 3,
              width: '107px', // Set the width to your desired size
              height: '200px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '18px 18px 18px 18px',
              boxShadow: 'none'
          }}
        />

        <Box 
          sx={{
              background: `linear-gradient(to bottom, ${firstColor}, ${secondColor})`,
              position: 'absolute',
              top: 105,
              right: 13,
              zIndex: 2,
              width: '121px', // Set the width to your desired size
              height: '263px',
              pointerEvents: 'none', // Make the image non-interactable
              borderRadius: '10px',
          }}
        />

        <img
          src={mobile}
          alt="Mobile Image"
          style={{
            position: 'absolute',
            top: 80,
            right: -50,
            zIndex: 1,
            width: '250px', // Set the width to your desired size
            height: 'auto',
            pointerEvents: 'none', // Make the image non-interactable
          }}
        />

      </React.Fragment>
    )
  }

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>

      <Box sx={{
        display: `${!isMediumScreen ? 'block' : 'none'}`
      }}>
        <RenderMobileLarge 
          ticket={ticket}
          eventTitle={eventTitle}
          location={location}
          dateValue={dateValue}
          tickets={tickets}
          logoDisplay={logoDisplay}
          firstColor={firstColor}
          secondColor={secondColor}
          mobile={mobile}
        />
      </Box>

      <Box sx={{
        display: `${isMediumScreen && !isMediumTablet ? 'block' : 'none'}`
      }}>
        <RenderMobileMedium 
          ticket={ticket}
          eventTitle={eventTitle}
          location={location}
          dateValue={dateValue}
          tickets={tickets}
          logoDisplay={logoDisplay}
          firstColor={firstColor}
          secondColor={secondColor}
          mobile={mobile}
        />
      </Box>

      <Box sx={{
        display: `${isMediumTablet ? 'block' : 'none'}`
      }}>
        <RenderMobileSmall 
          ticket={ticket}
          eventTitle={eventTitle}
          location={location}
          dateValue={dateValue}
          tickets={tickets}
          logoDisplay={logoDisplay}
          firstColor={firstColor}
          secondColor={secondColor}
          mobile={mobile}
        />
      </Box>

      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { display: 'none' } }}  // Hides the default bottom indicator
        >
          <Tab
            label={
              <Stack direction="row" spacing={2}>
                {value === 0 && <RadioButtonCheckedIcon sx={{ color: '#FF0065' }} />}
                <Typography sx={{ textTransform: 'none', fontWeight: 500, fontFamily: 'Filson Pro Regular' }}>Single Event</Typography>
              </Stack>
            }
            {...a11yProps(0)}
            sx={{
              "&.Mui-selected": {
                color: 'black', // text color for the active tab
                backgroundColor: '#F3F1F6', // gray background for the active tab
                borderRadius: '5px 5px 0 0', // border radius for the active tab'
                textTransform: 'none'
              }
            }}
          />
          <Tab
            label={
              <Stack direction="row" spacing={2}>
                {value === 1 && <RadioButtonCheckedIcon sx={{ color: '#FF0065' }} />}
                <Typography variant="subtitle1" sx={{ textTransform: 'none', fontWeight: 500, fontFamily: 'Filson Pro Regular' }}>Bulk Event</Typography>
              </Stack>
            }
            {...a11yProps(1)}
            sx={{
              "&.Mui-selected": {
                color: 'black', // text color for the active tab
                backgroundColor: '#F3F1F6', // gray background for the active tab
                borderRadius: '5px 5px 0 0', // border radius for the active tab'
                textTransform: 'none'
              }
            }}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Box sx={{
            height: isMediumTablet ? '380px' : '450px',
            pt: 4,  // Adds padding to the top
            px: 4,  // Adds padding to the left and right
            mb: 2,
        }}>
          <Grid container sx={{ display: 'flex', flexDirection: 'row', }}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Typography variant="h5" sx={{ color: '#00000', fontFamily: "Alverata Bold Italic", ml: "3px !important"}} >Add Event</Typography>
                {/* <Box sx={{ mt: "-5px !important" }}>
                  <Tooltip title="Refresh">
                    <IconButton onClick={refreshWindow}>
                      <RefreshIcon sx={{ height: 30, width: 30, color: '#7500FF' }} />
                    </IconButton>
                  </Tooltip>
                </Box> */}
              </Stack>
              <Box sx={{
                  display: 'flex',
                  justifySelf: 'flex-start',
                  alignItems: 'left',
                  flexDirection: 'column',
                  pt: 2,  // Adds padding to the top
                  px: 14,  // Adds padding to the left and right
                  mb: 8,
                }}>
                  <InputBootstrap label="Opponent" state={setEventTitle} dataType="String" width={isMediumLaptop && '200px'} />
                  <Box sx={{ mt: 2 }} />

                  <InputBootstrap label="Price" state={setPrice} dataType="Float" width={isMediumLaptop && '200px'} />
                  <Box sx={{ mt: 2 }} />

                  <InputBootstrap label="No. of Tickets" state={setTickets} dataType="Int" width={isMediumLaptop && '200px'} />
                  <Box sx={{ mt: 2 }} />

                  <InputBootstrap label="Category" state={setCategory} dataType="String" width={isMediumLaptop && '200px'} />
                  <Box sx={{ mt: 2 }} />
                  <Box sx={{ mt: 2 }} />
                  <Box sx={{
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                    {/* <InputBootstrap label="Location (optional)" state={setLocation} dataType="String" width={isMediumLaptop && '200px'} /> */}
                    <Box sx={{ mt: -1.2, mb: 0.5 }}>
                      <DatePick date={SetDateValue} width={isMediumLaptop && '200px'} />
                    </Box>
                    <Box sx={{ mr: 1 }} />
                    <Box sx={{
                      display: 'flex',
                      justiyContent: 'flex-start',
                      alignItems: 'left',
                      flexDirection: 'column',
                      mt: -2.8
                    }}>
                      <Typography variant="body2" sx={{ color: '#0000007D', fontWeight: 400, fontFamily: 'Filson Pro Regular' }}>Colors</Typography>
                      <Box sx={{
                        display: 'flex',
                        justiyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row', 
                      }}>
                        {isColorPickerOpen && (
                          <Box sx={{
                            zIndex: 1,
                            position: 'absolute',
                            mt: -50,
                            left: !isMediumLaptop ? 365 : isMediumTablet ? 180 : 210
                          }}>
                            <Box sx={{
                              // display: 'flex',
                              // justifyContent: 'flex-end',
                              zIndex: 2,
                              position: 'absolute',
                              top: -18,
                              left: 200
                            }}>
                              <IconButton onClick={handleFirstColorClosed}> 
                                <CancelIcon sx={{ color: '#C3136B'  }} />
                              </IconButton>
                            </Box>
                            <SketchPicker
                              color={firstColor}
                              onChange={(color) => {
                                Cookies.set("firstColor", color.hex)
                                setFirstColor(color.hex)
                              }}
                            />
                          </Box>
                        )}

                        {isSecondColorPickerOpen && (
                          <Box sx={{
                            zIndex: 1,
                            position: 'absolute',
                            mt: -50,
                            left: !isMediumLaptop ? 435 : isMediumTablet ? 190 : 255
                          }}>
                            <Box sx={{
                              // display: 'flex',
                              // justifyContent: 'flex-end',
                              zIndex: 2,
                              position: 'absolute',
                              top: -18,
                              left: 200
                            }}>
                              <IconButton onClick={handleSecondColorClosed}> 
                                <CancelIcon sx={{ color: '#C3136B'  }} />
                              </IconButton>
                            </Box>
                            <SketchPicker
                              color={secondColor}
                              onChange={(color) => {
                                Cookies.set("SecondColor", color.hex)
                                setSecondColor(color.hex)
                              }}
                            />
                          </Box>
                        )}

                        <Button variant="contained" sx={firstButtonStyle} onClick={handleColorPickerToggle} />
                        <Box sx={{ mr: 1 }} />

                        <Button variant="contained" sx={secondButtonStyle} onClick={handleSecondColorPickerToggle} />

                      </Box>

                    </Box>
                  </Box>
                </Box>
            </Grid>
          </Grid>

          {/* <Grid container spacing={2}>
              <Grid item xs={8}>
                  <Box sx={{
                      display: 'flex', 
                      flexDirection: 'column',
                      justifyContent: 'center', 
                      height: `${ isMediumScreen ? '60%' : '100%'}`, // adjust as needed
                      alignItems: 'center',
                      pr: 8,
                      ml: 17,
                      mt: 2,
                  }}>
                      <Upload onFileUpload={handleLogoUpload} setLogoDisplay={setLogoDisplay} />
                  </Box>
                  
              </Grid>
          </Grid> */}
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        <Box sx={{
          height: isMediumTablet ? '250px' : '450px',
          pt: 4,  // Adds padding to the top
          px: 4,  // Adds padding to the left and right
          mb: 8,
        }}>
          <Grid container sx={{ display: 'flex', flexDirection: 'row', }}>
            <Grid item xs={12}>

              <Stack direction="row" spacing={1}>
                <Typography variant="h5" sx={{ color: '#00000', fontFamily: "Alverata Bold Italic" }}>Add Bulk Events</Typography>
                <Box sx={{ mt: "-5px !important" }}>
                  <Tooltip title="Download CSV Template">
                    <IconButton onClick={downloadTemplate}>
                      <DownloadForOfflineIcon sx={{ height: 30, width: 30, color: '#7500FF' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
              
              <Box sx={{
                display: 'flex',
                justifySelf: 'flex-start',
                alignItems: 'left',
                flexDirection: 'column',
                px: bulkPadding,
                pt: 10
              }}>
                <Box sx={{
                  display: 'flex',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}>

                  <UploadFile onFileUpload={handleFileUpload} files={file} setFiles={setFile}/>
                  <Box sx={{ mr: 2 }} />

                  <Box sx={{
                    display: 'flex',
                    justiyContent: 'flex-start',
                    alignItems: 'left',
                    flexDirection: 'column',
                    mt: -2.8
                  }}>
                    <Typography variant="body2" sx={{ color: '#0000007D', fontWeight: 400 }}>Colors</Typography>
                    <Box sx={{
                      display: 'flex',
                      justiyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row', 
                    }}>

                      {isColorPickerOpen && (
                        <Box sx={{
                          zIndex: 1,
                          position: 'absolute',
                          mt: -50,
                          left: !isMediumLaptop ? 390 : isMediumTablet ? 145 : 210
                        }}>
                          <Box sx={{
                              // display: 'flex',
                              // justifyContent: 'flex-end',
                              zIndex: 2,
                              position: 'absolute',
                              top: -18,
                              left: 200
                            }}>
                              <IconButton onClick={handleFirstColorClosed}> 
                                <CancelIcon sx={{ color: '#C3136B'  }} />
                              </IconButton>
                            </Box>
                          <SketchPicker
                            color={firstColor}
                            onChange={(color) => {
                              Cookies.set("firstColor", color.hex)
                              setFirstColor(color.hex)
                            }}
                          />
                        </Box>
                      )}

                      {isSecondColorPickerOpen && (
                        <Box sx={{
                          zIndex: 1,
                          position: 'absolute',
                          mt: -50,
                          left: !isMediumLaptop ? 460 : isMediumTablet ? 190 : 255 
                        }}>
                          <Box sx={{
                              // display: 'flex',
                              // justifyContent: 'flex-end',
                              zIndex: 2,
                              position: 'absolute',
                              top: -18,
                              left: 200
                            }}>
                              <IconButton onClick={handleSecondColorClosed}> 
                                <CancelIcon sx={{ color: '#C3136B'  }} />
                              </IconButton>
                            </Box>
                          <SketchPicker
                            color={secondColor}
                            onChange={(color) => {
                              Cookies.set("SecondColor", color.hex)
                              setSecondColor(color.hex)
                            }}
                          />
                        </Box>
                      )}

                      <Button variant="contained" sx={firstButtonStyle} onClick={handleColorPickerToggle} />
                      <Box sx={{ mr: 1 }} />

                      <Button variant="contained" sx={secondButtonStyle} onClick={handleSecondColorPickerToggle} />
                    
                    </Box>
                  </Box>

                </Box>
              </Box>

            </Grid>
          </Grid>

          {/* <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box sx={{
                  display: 'flex', 
                  flexDirection: 'column',
                  justifyContent: 'center', 
                  height: `${ isMediumScreen ? '60%' : '100%'}`, // adjust as needed
                  alignItems: 'center',
                  px: bulkPadding,
                  pr: 8,
                  ml: 1,
                  mt: 2,
              }}>
                  <Upload onFileUpload={handleLogoUpload} setLogoDisplay={setLogoDisplay} />
              </Box>
            </Grid>
          </Grid> */}

        </Box>
      </CustomTabPanel>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 3
      }}>
        <Button 
          startIcon={<SaveOutlinedIcon />}
          onClick={() => handleSave(value)}
          sx={{
            color: '#fff',
            height: '48px',
            width: '150px',
            background: 'transparent linear-gradient(90deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
            border: '1px solid #00000000',
            opacity: 1,
            borderRadius: "14px",
            textTransform: 'none',
            fontSize: '20px',
            fontFamily: 'Filson Pro Bold',
            "&:hover": {
              color: '#fff',
              background: 'transparent linear-gradient(90deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
              border: '2px solid #FFFFFF',
              borderRadius: "14px",
            }
          }}>
            Save
        </Button>
      </Box>
      
    </Box>
  );
}

export default NavEventStyles