// React builtin components imports
import React from 'react';

// Custom component React


// Material UI
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';

// Icons and Images
import Logo from '../../static/navigation/waveWhiteNew.png';
import { Link } from '@mui/material';


const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Navbarlogo = () => {
    return (
      <AppBar position="static" sx={{ background: "radial-gradient(circle at 47.74248758951823% 27.788899739583332%, #5000FF 0%, 38.76%, rgba(80,0,255,0) 68%), radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #5000FF 0%, 13.200000000000001%, rgba(80,0,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(80,0,255,0.99) 0%, 25%, rgba(80,0,255,0) 50%), radial-gradient(circle at 5.416666666666667% 10%, #FF0051 0%, 42%, rgba(255,0,81,0) 70%), radial-gradient(circle at 93.90768686930339% 11.448923746744793%, #7500FF 0%, 42%, rgba(117,0,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)", boxShadow: "none" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>

          <Link href="https://wave.tickets" rel="noopener noreferrer">
            <Box
                component="img"
                sx={{
                    height: "53px",
                    display: { xs: 'flex', color: '#000' },
                }}
                alt="Wavelogo"
                src={Logo}
            />
          </Link>
  
          </Toolbar>
        </Container>
      </AppBar>
    );
}

export default Navbarlogo