import React from 'react'
import { Typography, Button, Box, Paper, Stack, Chip, useMediaQuery,  } from '@mui/material'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

const UploadFile = (props) => {
    const [inputKey, setInputKey] = React.useState(0);

    const isSmall = useMediaQuery('(max-width:)')

    React.useEffect(() => {
        // Update the input key whenever props.files changes
        setInputKey((prevKey) => prevKey + 1);
    }, [props.files]);

    const handleFileUpload = (event) => {
        const file = event.target.files;
        props.onFileUpload(file);
    }

    const handleDelete = (data) => () => {
        props.setFiles(null);
    };
    
    return (
    <Box
        sx={{
            background: '#fff',
            height: '40px',
            width: {xs: '250px', md: '315px'},
            border: '1px solid #70707038',
            borderRadius: '6px',
            mt: -0.6
        }}
    >
        <Stack direction="row" spacing={2}>
        
            <Button
                component="label"
                sx={{
                    width: '35%',
                    height: '100%',
                }}
            >
                <Typography variant='h6' sx={{ textTransform: 'none', mt: { xs: 0.6, md: 0.3 }, textAlign: 'left', color: '#0000007D', fontFamily: "Filson Pro Regular", fontSize: { xs: '12px', md: '16px'} }}>
                    Upload csv
                </Typography>
                <input key={inputKey} hidden accept='.csv' type='file' onChange={handleFileUpload} />
            </Button>

            {
                props.files !== null && props.files !== 0
                ?
                <Paper
                    sx={{
                        boxShadow: 'none',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        background: '#fff',
                        mt: "4px !important",
                        mr: "5px !important",
                        flexGrow: 1

                    }}
                    component="ul"
                >
                    <Stack direction="row" spacing={2}>
                        <Chip
                            label={
                                <span style={{ fontSize: '10px' }}>
                                    {props.files[0].name}
                                </span>
                            }
                            onDelete={handleDelete(props.files[0])}
                            sx={{ color: 'gray', border: '1px solid #707070', background: '#fff' }}
                            deleteIcon={<CancelIcon style={{ color: 'gray' }} />}
                        />
                    </Stack>
                </Paper>
                : ""
            }

        </Stack>

    </Box>
    )
}

export default UploadFile