import React from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'js-cookie'

// Material UI Component
import { Box, Grid, useMediaQuery } from '@mui/material';
// Custom component React
import Navbar from '../components/Navigation/Navbar';
import HorizontalNav from '../components/Navigation/HorizontalNav';
import NavEventStyles from '../components/tabs/NavEventStyles';

// Images and logo

const AddEvent = ({ activeTab, setActiveTab }) => {
    const isSmallScreen = useMediaQuery('(max-width:950px)')
    const isTablet = useMediaQuery('(max-width:830px)')
    const navigate = useNavigate();
    const token = Cookies.get("token");

    React.useEffect(() => {
        if (!token) {
          navigate('/')
        }
    }, [])
  return (
    <>
        <Navbar />

        <Box sx={{
            background: "radial-gradient(circle at 47.74248758951823% 27.788899739583332%, #5000FF 0%, 38.76%, rgba(80,0,255,0) 68%), radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #5000FF 0%, 13.200000000000001%, rgba(80,0,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(80,0,255,0.99) 0%, 25%, rgba(80,0,255,0) 50%), radial-gradient(circle at 5.416666666666667% 10%, #FF0051 0%, 42%, rgba(255,0,81,0) 70%), radial-gradient(circle at 93.90768686930339% 11.448923746744793%, #7500FF 0%, 42%, rgba(117,0,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)",
            height: '495px',
            pt: 4,  // Adds padding to the top
            px: 5,  // Adds padding to the left and right
            mb: 10
        }}>

            <Box sx={{
                height: 'auto',
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: '1px solid #BCBCBC',
                borderRadius: "20px",
                pt: 5,  // Adds padding to the top
                px: isSmallScreen ? 5 : 10,  // Adds padding to the left and right
                position: 'relative' // Added to establish a positioning context
            }}>
            
                <Grid container sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                        {
                            isTablet 
                            ? (
                                <Box sx={{ width: isTablet && 400 }}>
                                    <HorizontalNav activeTab={activeTab} setActiveTab={setActiveTab} />
                                </Box>
                            ) : (
                                <HorizontalNav activeTab={activeTab} setActiveTab={setActiveTab} />
                            )
                        }
                    </Grid>
                </Grid>

                <Grid container sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', mt: 2, pb: 5 }}>
                    <Grid item xs={12}> {/* Adjust the Grid size as per your requirement */}
                    
                        <NavEventStyles />    
                    
                    </Grid>
                </Grid>
                
            </Box>
        </Box>
    </>
  )
}

export default AddEvent
