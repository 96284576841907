import React from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 75,
  height: 40,
  padding: 8,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    transform: 'translateX(-16px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(20px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#fff',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#F2F2F2',
    height: 20,
    width: 20,
    marginLeft: 22,
    marginTop: 6,
    borderRadius: '50%',
    border: '3px solid #4D00FF',
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#fff',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '19px',
  },
  '& .MuiSwitch-thumb.Mui-focusVisible': {
    border: '3px solid #4D00FF',
  },
  '& .MuiSwitch-thumb:after': {
    content: '"ON"',
    fontFamily: 'Filson Pro Regular',
    fontSize: '14px',
    color: "#4D00FF",
    position: 'absolute',
    top: '60%',
    marginLeft: '-60%',
    transform: 'translateY(-50%)',
    width: 16,
    height: 16,
    opacity: 0, // Initially hidden
  },
  '& .Mui-checked .MuiSwitch-thumb:after': {
    opacity: 1, // Show the "ON" label when checked
  },
  '& .MuiSwitch-thumb:before': {
    content: '"OFF"',
    fontFamily: 'Filson Pro Regular',
    fontSize: '14px',
    color: "#4D00FF",
    position: 'absolute',
    top: '60%',
    marginLeft: '52%',
    transform: 'translateY(-50%)',
    width: 16,
    height: 16,
  },
  '& .Mui-checked .MuiSwitch-thumb:before': {
    content: '""', // Show the "OFF" label when checked
  },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

const ButtonSwitch = (props) => {
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.status(event.target.checked)
  };

  return (
    <MaterialUISwitch
      {...label}
      checked={checked}
      onChange={handleChange}
    />
  );
};

export default ButtonSwitch;
