import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { currencyFormatter } from "../tables/BillRecieve";

function createData(name, category, purchase, price) {
  return { name, category, purchase, price };
}

const rows = [
  createData("John Doe", "Football", 6, 24),
  createData("Kim Carry", "Football", 9, 37),
  createData("Eclair Jonas", "Football", 16, 24),
  createData("James Anderson", "Football", 3, 67),
  createData("Ginger Kolsan", "Football", 16, 49),
  createData("Ginger Kolsan", "Football", 16, 49),
  createData("Ginger Kolsan", "Football", 16, 49),
  createData("Ginger Kolsan", "Football", 16, 49),
  createData("Ginger Kolsan", "Football", 16, 49),
  createData("Ginger Kolsan", "Football", 16, 49),
];

const InfoDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [billings, setBillings] = useState([]);

  const handleClose = () => {
    props.openDialogFunc(false);
  };

  const handleSave = () => {
    console.log("Api edit event will be hit in this function");
  };

  const getBillings = async (config) => {
    try {
      setIsLoading(true);
      if (Boolean(props.infoData)) {
        const { data } = await axios.get(
          `https://api.wave.tickets/api/billing?event_id=${props.infoData.id}`,
          config
        );
        setBillings(data.billing.data);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        Accept: "application/json",
      },
    };
    getBillings(config);
  }, [props]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.openDialog}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{ fontFamily: "Filson Pro Bold" }}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="h6" sx={{ fontFamily: "Alverata Md Italic" }}>
            {props.infoData && props.infoData.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: "10px !important", fontFamily: "Filson Pro Light" }}
          >
            (Created By - {props.infoData && props.infoData.createdBy})
          </Typography>
        </Stack>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableContainer sx={{ maxHeight: 235 }}>
              <Table
                sx={{ minWidth: 200 }}
                size="small"
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ color: "#7500FF", fontFamily: "Filson Pro Medium" }}
                    >
                      User Name
                    </TableCell>
                    <TableCell
                      sx={{ color: "#7500FF", fontFamily: "Filson Pro Medium" }}
                      align="right"
                    >
                      Category
                    </TableCell>
                    <TableCell
                      sx={{ color: "#7500FF", fontFamily: "Filson Pro Medium" }}
                      align="right"
                    >
                      Purchases
                    </TableCell>
                    <TableCell
                      sx={{ color: "#7500FF", fontFamily: "Filson Pro Medium" }}
                      align="right"
                    >
                      Prices ($)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billings.map((bill) => (
                    <TableRow
                      key={bill.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ fontFamily: "Filson Pro Regular" }}
                      >
                        {bill.charge_data.customer_details.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Filson Pro Light" }}
                      >
                        {bill.event.category}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Filson Pro Light" }}
                      >
                        {bill.quantity}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontFamily: "Filson Pro Light" }}
                      >
                        {currencyFormatter(
                          bill.event.ticket_price * bill.quantity,
                          bill.charge_data.currency
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleClose} sx={{ color: "#FF0065" }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
