import React from 'react';

// Custom component React

// Material UI Component
import { Button } from '@mui/material';

// Images and logo
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import APP_CONFIG from '../../Const';

const ButtonGradientUpload = (props) => {

    const handleFileUpload = async (event) => {

        const file = event.target.files;
        const csvFile = file[0]
        const formData = new FormData();
        Array.from(file).forEach(file => {
          formData.append('contacts', csvFile);
        })

        try {

            let result = await fetch("https://api.wave.tickets/api/contacts/upload-csv", {
              method: "POST",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
              body: formData,
            });
  
            if (result.status === 200) {
              toast("Event Added Successfully", {type: ["success"]})
              setTimeout(() => {
                window.location.reload(true)
              }, 1000);
            } else {
              toast("File can not be sent. Check your internet connection", { type: ["error"] })
            }
  
          } catch {
            toast("File size error or check your internet connection", { type: ["error"] })
        }

    }

    return (
        <Button
            component="label"
            startIcon={props.icon}
            sx={{
                color: '#fff',
                height: '48px',
                width: '150px',
                background: 'transparent linear-gradient(90deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
                border: '1px solid #00000000',
                opacity: 1,
                borderRadius: "14px",
                textTransform: 'none',
                fontSize: '20px',
                fontFamily: 'Filson Pro Bold',
                "&:hover": {
                    color: '#fff',
                    background: 'transparent linear-gradient(90deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
                    border: '2px solid #FFFFFF',
                    borderRadius: "14px",
                }
            }}>
                {props.text}
                <input hidden accept='.csv' type='file' onChange={handleFileUpload} />
        </Button>
    )
}

export default ButtonGradientUpload