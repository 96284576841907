// React builtin components imports
import React from 'react';

// Third-party components imports
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast, TypeOptions } from "react-toastify";
import "react-toastify/ReactToastify.min.css";

// Custom component imports
import AppRoutes from './AppRoutes';

// Styling sheet imports
import './App.css';

function App() {

  React.useEffect(() => {
    const handleWindowReload = () => {
      window.location.reload(); // Reload the page
    };

    // Listen for the popstate event to detect manual navigation
    window.addEventListener('popstate', handleWindowReload);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      window.removeEventListener('popstate', handleWindowReload);
    };
  }, []);

  return (
    <BrowserRouter basename="/">
      <ToastContainer
        newestOnTop
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
