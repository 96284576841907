import { TextField } from '@mui/material'
import React from 'react';
import './InputBootstrap.css'
import { toast } from 'react-toastify';


const InputBootstrapMulti = (props) => {
    const [errorValue, setErrorValue] = React.useState(false);

    const customStyles = {
      background: 'white',
      border: '1px solid',
      borderColor: '#9d9d9d1f',
      borderRadius: "5px",
      height: '200px',
      width: `${props.width ? props.width : '330px'}`,
      overFlowY: 'scroll',
      '& .MuiInputLabel-root': {
          fontFamily: 'Filson Pro Light', // Set the font family to Open Sans for the label
      },
    };
  
    const handleChangeInput = (event) => {
      const inputValue = event.target.value;
  
      if (props.dataType === 'String') {
        props.text(inputValue);
      } else if (props.dataType === 'Float') {
        // Validate for float input (allow digits and one dot)
        const regex = /^[0-9.]*$/;
        if (regex.test(inputValue)) {
          props.text(inputValue);
          setErrorValue(false);
        }
        else {
          props.state('')
          setErrorValue(true);
        }
      } else if (props.dataType === 'Int') {
        // Validate for Integre input (allow digits only)
        const regex = /^[0-9]*$/;
        if (regex.test(inputValue)) {
          props.text(inputValue);
          setErrorValue(false);
        }
        else {
          props.text('')
          setErrorValue(true);
        }
      }
    }

  return (
    <TextField 
        id="bootstrap-basic"
        multiline
        error={errorValue}
        defaultValue={props.label}
        variant="outlined"
        // disabled
        sx={{
            '& .MuiInputBase-root': customStyles,
            boxShadow: 'none'
        }}
        onChange={handleChangeInput}
    />
  )
}

export default InputBootstrapMulti