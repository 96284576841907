import { TextField } from '@mui/material'
import React from 'react';
import './InputBootstrap.css'
import { toast } from 'react-toastify';

const InputBootstrap = (props) => {
  const [errorValue, setErrorValue] = React.useState(false);

  const customStyles = {
    background: 'white',
    border: '1px solid',
    borderColor: '#9d9d9d1f',
    borderRadius: "5px",
    width: `${props.width ? props.width : '325px'}`,
    '& .MuiInputLabel-root': {
        fontFamily: 'Filson Pro Light', // Set the font family to Open Sans for the label
    },
  };

  const handleChangeInput = (event) => {
    const inputValue = event.target.value;

    if (props.dataType === 'String') {
      props.state(inputValue);
    } else if (props.dataType === 'Float') {
      // Validate for float input (allow digits and one dot)
      const regex = /^[0-9.]*$/;
      if (regex.test(inputValue)) {
        props.state(inputValue);
        setErrorValue(false);
      }
      else {
        props.state('')
        setErrorValue(true);
      }
    } else if (props.dataType === 'Int') {
      // Validate for Integre input (allow digits only)
      const regex = /^[0-9]*$/;
      if (regex.test(inputValue)) {
        props.state(inputValue);
        setErrorValue(false);
      }
      else {
        props.state('')
        setErrorValue(true);
      }
    }
  }
  
  return (
    <TextField 
        id={props.type}
        error={errorValue}
        defaultValue={props.defaultValue}
        type={props.type}
        label={
            <span className="labelFont">{props.label}</span> // Apply the custom CSS class to the label
          }
        variant="outlined" 
        size="small"
        sx={{
            '& .MuiInputBase-root': customStyles,
            boxShadow: 'none'
        }}
        onChange={handleChangeInput}
    />
  )
}

export default InputBootstrap