import React from "react";

// Material UI Component
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CircleIcon from "@mui/icons-material/Circle";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Custom component React
import Navbar from "../components/Navigation/Navbar";
import HorizontalNav from "../components/Navigation/HorizontalNav";
import ButtonGradient from "../components/button/ButtonGradient";

// Images and logo
import BackupIcon from "@mui/icons-material/Backup";
import Searchbar from "../components/search/Searchbar";
import ContactTable from "../components/tables/ContactTable";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ButtonGradientUpload from "../components/button/ButtonGradientUpload";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import APP_CONFIG from "../Const";

const drawerWidth = 400;

function formatDate(inputDate) {
  const dateObject = new Date(inputDate);
  const options = { month: "short", day: "numeric" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    dateObject
  );
  return formattedDate;
}

function formatTime24(inputDate) {
  const dateObject = new Date(inputDate);

  // Get hours and minutes
  const hours = dateObject.getUTCHours().toString().padStart(2, "0");
  const minutes = dateObject.getUTCMinutes().toString().padStart(2, "0");

  // Format as 24-hour clock time
  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: "#FF0065",
      color: "#fff",
    },
    children: name.charAt(0), // or use: name[0]
  };
}

const Contacts = ({ activeTab, setActiveTab }) => {
  const [state, setState] = React.useState(false);
  const [name, setName] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [chatHistory, setChatHistory] = React.useState([]);
  const isSmallScreen = useMediaQuery("(max-width:950px)");
  const isTablet = useMediaQuery("(max-width:830px)");
  const navigate = useNavigate();
  const token = Cookies.get("token");

  React.useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, []);

  const downloadTemplate = () => {
    window.location.href = "http://api.wave.tickets/contacts-sample.csv";
  };

  const toggleDrawer = (open, name, phone, id) => (event) => {
    console.log(id, name, open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
    setName(name);
    setPhone(phone);

    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        Accept: "application/json",
      },
    };

    axios
      .get(`https://api.wave.tickets/api/contact/messages/${id}`, config)
      .then((response) => {
        setChatHistory(response.data.messages);
      })
      .catch((error) => {
        console.log("Error created");
      });
  };

  const list = (data) => (
    <Box sx={{ width: drawerWidth }} role="presentation">
      <Box
        sx={{
          background: "#F7F7F7",
          height: "50px",
          px: 3,
          py: 3,
          position: "sticky",
          zIndex: 1,
          top: 0,
        }}
      >
        <Grid container>
          {name && (
            <React.Fragment>
              <Grid item xs={2} sx={{ mt: 0.5 }}>
                <Avatar {...stringAvatar(name)} />
              </Grid>
              <Grid item xs={9}>
                <Stack direction="column">
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Filson Pro Bold" }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Filson Pro Light" }}
                  >
                    {phone}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item
                xs={1}
                marginTop={0.2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <MoreVertIcon
                  sx={{ height: 40, width: 40, color: "#bdbdbd" }}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Box>

      <Box
        sx={{
          overflowY: "scroll",
          mt: 3,
          px: 5,
          flex: 1,
        }}
      >
        {data &&
          data.map((item, index) => (
            <React.Fragment key={index}>
              {item.from === "ai" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  {item.type !== "qr" ? (
                    <Box
                      sx={{
                        p: 2,
                        background: "#E3E3E3",
                        width: "50%",
                        height: "auto",
                        borderRadius: "20px",
                        overflowY: "auto",
                        // textOverflow: 'ellipsis',
                        // whiteSpace: 'nowrap',
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ color: "#000", fontFamily: "Filson Pro Regular" }}
                        dangerouslySetInnerHTML={{ __html: item.message }}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ width: "60%" }}>
                      <img
                        src={item.message}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                  )}

                  <Typography variant="caption" sx={{ fontSize: "10px" }}>
                    <span
                      style={{
                        fontFamily: "Filson Pro Light",
                        color: "#949494",
                      }}
                    >
                      {formatDate(item.created_at)}
                    </span>
                    <CircleIcon
                      sx={{
                        height: 6,
                        width: 6,
                        mt: 1,
                        ml: 0.5,
                        mr: 0.5,
                        color: "#4D00FF",
                      }}
                    />
                    <span
                      style={{
                        fontFamily: "Filson Pro Medium",
                        color: "#929292",
                      }}
                    >
                      {formatTime24(item.created_at)}
                    </span>
                  </Typography>
                </Box>
              )}

              {item.from === "user" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      background: "#4D00FF",
                      width: "50%",
                      height: "auto",
                      borderRadius: "20px",
                      mt: 2,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "#fff", fontFamily: "Filson Pro Regular" }}
                    >
                      {item.message}
                    </Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    sx={{ fontSize: "10px", ml: 0.2 }}
                  >
                    <span
                      style={{
                        fontFamily: "Filson Pro Light",
                        color: "949494",
                      }}
                    >
                      {formatDate(item.created_at)}
                    </span>
                    <CircleIcon
                      sx={{
                        height: 6,
                        width: 6,
                        mt: 1,
                        ml: 0.5,
                        mr: 0.5,
                        color: "#4D00FF",
                      }}
                    />
                    <span
                      style={{
                        fontFamily: "Filson Pro Medium",
                        color: "#929292",
                      }}
                    >
                      {formatTime24(item.created_at)}
                    </span>
                  </Typography>
                </Box>
              )}
            </React.Fragment>
          ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Navbar />

      <Box
        sx={{
          background:
            "radial-gradient(circle at 47.74248758951823% 27.788899739583332%, #5000FF 0%, 38.76%, rgba(80,0,255,0) 68%), radial-gradient(circle at 94.35384114583333% 89.61588541666666%, #5000FF 0%, 13.200000000000001%, rgba(80,0,255,0) 40%), radial-gradient(circle at 6.503906249999999% 88.037109375%, rgba(80,0,255,0.99) 0%, 25%, rgba(80,0,255,0) 50%), radial-gradient(circle at 5.416666666666667% 10%, #FF0051 0%, 42%, rgba(255,0,81,0) 70%), radial-gradient(circle at 93.90768686930339% 11.448923746744793%, #7500FF 0%, 42%, rgba(117,0,255,0) 70%), radial-gradient(circle at 48.9013671875% 49.521484375%, #FFFFFF 0%, 100%, rgba(255,255,255,0) 100%)",
          height: "495px",
          pt: 4, // Adds padding to the top
          px: 5, // Adds padding to the left and right
          mb: 10,
        }}
      >
        <Box
          sx={{
            height: "auto",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            border: "1px solid #BCBCBC",
            borderRadius: "20px",
            pt: 5, // Adds padding to the top
            px: isSmallScreen ? 5 : 10, // Adds padding to the left and right
            pb: 8,
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isTablet ? (
                <Box sx={{ width: isTablet && 400 }}>
                  <HorizontalNav
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </Box>
              ) : (
                <HorizontalNav
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 3,
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ButtonGradientUpload icon={<BackupIcon />} text="Upload" />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
            <Grid item xs={6}>
              <Stack direction="row" spacing={2.5}>
                <Typography
                  variant="h5"
                  sx={{ color: "#00000", fontFamily: "Alverata Bold Italic" }}
                >
                  Contacts
                </Typography>
                <Box
                  sx={{
                    mt: "-5px !important",
                  }}
                >
                  <Searchbar />
                </Box>
                <Box
                  sx={{
                    mt: "-5px !important",
                    ml: "-5px !important",
                  }}
                >
                  <Tooltip title="Download Sample CSV">
                    <IconButton onClick={downloadTemplate}>
                      <DownloadForOfflineIcon
                        sx={{ height: 30, width: 30, color: "#7500FF" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <ContactTable
                toggler={toggleDrawer}
                setname={setName}
                setphone={setPhone}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Drawer
        anchor="right"
        open={state}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            borderRadius: "31px 0 0 31px",
            background: "#FFFFFF",
            boxShadow: "-10px 0px 6px #00000029",
            border: "1px solid #707070",
          },
        }}
      >
        {list(chatHistory)}
      </Drawer>
    </>
  );
};

export default Contacts;
