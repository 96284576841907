import React from 'react'
import {Box, IconButton } from '@mui/material'
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import APP_CONFIG from '../../Const';

const ButtonIcon = (props) => {

  const handleDownloadEventButton = async () => {
    try {

        const config = {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
                Accept: 'text/csv', // Requesting CSV format
            },
            responseType: 'blob', // Set responseType to 'blob' to receive raw data
        };

        const api = props.filterValue !== '' ? `https://api.wave.tickets/api/events?category=${props.filterValue}&export=true` : `https://api.wave.tickets/api/events?export=true`
        const response = await axios.get(api, config);
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'events.csv';
        link.click();
    } catch (error) {
        console.error('Error fetching or downloading data:', error);
        toast.error('Error downloading events data');
    }
};


  return (
  <Box sx={{ 
    borderRadius: "50%",
    border: "1px solid #4D00FF",
    width: '48px',  // Set width and height to be the same
    height: '48px', 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }}>
      <IconButton aria-label="button-id-icon" size="large" onClick={handleDownloadEventButton}>
          {props.icon}
      </IconButton>
  </Box>
)
}

export default ButtonIcon