import React from 'react';

// Custom component React

// Material UI Component
import { Box, Stack, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

// Images and logo

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: "6px",
      color: '#0000007',
      position: 'relative',
      backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#fff',
      border: '1px solid',
      borderColor: theme.palette.mode === 'light' ? '#70707038' : '#70707038',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  }));


const time = [
    { value: '1', label: '1 hour' }, 
    { value: '2', label: '2 hours' },
    { value: '3', label: '3 hours' },
    { value: '4', label: '4 hours' },
    { value: '5', label: '5 hours' },
    { value: '6', label: '6 hours' },
    { value: '7', label: '7 hours' },
    { value: '8', label: '8 hours' },
    { value: '9', label: '9 hours' },
    { value: '10', label: '10 hours' },
    { value: '11', label: '11 hours' },
    { value: '12', label: '12 hours' },
    { value: '13', label: '13 hours' },
    { value: '14', label: '14 hours' },
    { value: '15', label: '15 hours' },
    { value: '16', label: '16 hours' },
    { value: '17', label: '17 hours' },
    { value: '18', label: '18 hours' },
    { value: '19', label: '19 hours' },
    { value: '20', label: '20 hours' },
    { value: '21', label: '21 hours' },
    { value: '22', label: '22 hours' },
    { value: '23', label: '23 hours' },
    { value: '24', label: '24 hours' }
  ];

const FormStack = (props) => {
  return (
    <>
    {
        props.stackElement === 3 
        ?
            <Stack direction="row" spacing={2}>
                <Typography sx={{ mt: "10px !important", fontWeight: 700 }}>Pregame:</Typography>

                <FormControl variant="standard">
                    <BootstrapInput defaultValue="24 hour" id="bootstrap-input" />
                </FormControl>

                <Typography sx={{ mt: "12px !important", fontStyle: 'italic', color: '#000000', opacity: 0.44, letterSpacing: '0px', fontWeight: 300, fontSize: 14, letterSpacing: "0.5px" }}>Before scheduled start</Typography>

            </Stack> 
        :
        props.stackElement === 2 
        ?
            <Stack direction="row" spacing={2}>
                <Typography sx={{ mt: "10px !important", fontWeight: 700 }}>Text:</Typography>

                <FormControl variant="standard">
                    <BootstrapInput defaultValue='Huge game tomorrow night vs {opponent}! Reply "Tickets" to purchase early and skip the line! See you at the game!Reply STOP to End.' id="bootstrap-input" />
                </FormControl>

            </Stack> 
        :
        console.log("Not in our list")
    }
    </>
  )
}

export default FormStack