import React, { useState } from 'react';

// Material UI Component
import { Autocomplete, TextField, useMediaQuery } from '@mui/material';

// Images and logo

const categoryFilter = [
    { label: "4 hours", value: 4 },
    { label: "8 hours", value: 8 },
    { label: "24 hours", value: 24 },
    { label: "48 hours", value: 48 },
]

const Reminder = (props) => {

  const handleOnChange = (event, value) => {
    props.timer(value)
  }

  return (
    <Autocomplete
        size='small'
        id="category-filter"
        options={categoryFilter}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            size="small"
            {...params} 
            label={props.label}
            sx={{
              fontFamily: 'Filson Pro Regular',
              '& fieldset': {
                  fontFamily: 'Filson Pro Regular',
              },
              '& .MuiInputBase-root': {
                background: '#fff'
              },
              '& .MuiInputLabel-root': {
                  fontFamily: 'Filson Pro Light',
            },
            }} 
          />
        )}
    />
  )
}

export default Reminder