import React, { useState } from "react";
import { toast } from "react-toastify";

// Custom component React
import Cookies from "js-cookie";
import axios from "axios";

// Material UI Component
import { Autocomplete, TextField, useMediaQuery } from "@mui/material";

// Images and logo
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/system";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input": {
    padding: "5px 14px 12px 14px",
    lineHeight: "2",
  },
});

const categoryFilter = [
  { label: "Football", value: "filter1" },
  { label: "Cricket", value: "filter2" },
  { label: "Wrestilng", value: "filter3" },
  { label: "Table Tennis", value: "filter4" },
  { label: "Tennis", value: "filter4" },
  { label: "Baseball", value: "filter4" },
  { label: "Rugby", value: "filter4" },
  { label: "Chess", value: "filter4" },
  { label: "Snooker", value: "filter4" },
  { label: "UFC", value: "filter4" },
  { label: "EGames", value: "filter4" },
];

function createData(
  id,
  name,
  category,
  eventDate,
  createdBy,
  status,
  revenue,
  ticket_sold,
  ticket_count,
  venue,
  image,
  time,
  reminder
) {
  return {
    id,
    name,
    category,
    eventDate,
    createdBy,
    status,
    revenue,
    ticket_sold,
    ticket_count,
    venue,
    image,
    time,
    reminder,
  };
}

function datetime(dateString) {
  const datetimeString = dateString;
  const date = new Date(datetimeString);
  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate;
}

const Category = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [category, setCategory] = useState([]);
  const isMediumScreen = useMediaQuery("(max-width:1020px)");
  const [selectedFilter, setSelectedFilter] = useState("");

  const fontSizeValue = isMediumScreen ? "10px" : "15px";

  React.useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
        Accept: "application/json",
      },
    };

    axios
      .get(`https://api.wave.tickets/api/events/categories`, config)
      .then((response) => {
        const apiResponse = response.data.categories.map((category) => {
          return {
            label: category,
            value: category.toLowerCase(),
          };
        });
        setCategory(apiResponse);
      })
      .catch((error) => {
        toast("Network Error - Bad Request", { type: ["error"] });
      });
  }, []);

  const handleFilterChange = (event, value) => {
    if (value !== null) {
      props.onFilter(value.value);
    } else {
      props.onFilter(null);
    }
    setSelectedFilter(value); // Update the selected filter value in the state
  };

  return (
    <StyledAutocomplete
      disablePortal
      id="category-filter"
      options={category}
      onChange={handleFilterChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={!isFocused && props.label}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          sx={{
            width: "100%",
            borderRadius: "32px",
            border: "1px solid #BCBCBC",
            fontFamily: "Filson Pro Regular",
            "& fieldset": {
              borderRadius: "32px",
              border: "1px solid #BCBCBC",
              fontFamily: "Filson Pro Regular",
            },
            "&:hover": {
              borderColor: "#4D00FF",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                borderColor: "#4D00FF",
              },
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              height: "47px",
              alignItems: "center",
              display: "flex",
              fontWeight: 400,
              p: "0 0 0 10px",
              fontSize: fontSizeValue,
            },
            "& .MuiInputLabel-root": {
              color: "#000000", // set label color to white
              fontWeight: 500,
              mt: -0.4,
              ml: 0.4,
              fontFamily: "Filson Pro Regular",
            },
          }}
        />
      )}
      sx={{
        width: "100%",
        "& .MuiAutocomplete-popupIndicator": {
          color: "#4D00FF", // replace with your color
        },
        "&.Mui-focused .MuiAutocomplete-popupIndicator": {
          color: "#4D00FF",
        },
      }}
      popupIcon={
        <div
          style={{
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            border: "1px solid #4D00FF",
          }}
        >
          <ArrowDropDownIcon style={{ color: "#4D00FF" }} />
        </div>
      }
    />
  );
};

export default Category;
