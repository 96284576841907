import React, { useRef, useEffect } from 'react';
import { TextField }  from '@mui/material';

const InputText = (props) => {

  return (
    <>
    
    {/* <div style={{display: 'none'}}>
      <input type="text" name="fake-user-name"/>
      <input type="password" name="fake-password"/>
    </div> */}

    <TextField
      placeholder={props.label}
      id={props.id} 
      // label={props.label} 
      variant="outlined"
      onChange={props.onInputChange}
      type={props.type}
      autoFocus={false}
      inputProps={{
        pl: "20px !important",
        autocomplete: 'new-password',
        form: {
          autocomplete: 'off',
        },
      }}
      sx={{ 
        '& .MuiOutlinedInput-root': {
          borderRadius: '32px',
          border: '2px solid #FFFFFF',
          background: '#FFFFFF63',
          width: "356px",
          height: "52px",
          '&:hover': {
            borderColor: '#FFFFFF',
          },
          '&.Mui-focused': {
            borderColor: '#FFFFFF',
          },
          cursor: 'text',  // Set cursor to text
          '&:hover, &:focus': {
            cursor: 'text', // Ensure cursor remains as text when hovered or focused
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiInputLabel-root': {
          color: '#FFFFFF',  // set label color to white
          fontWeight: 300,
          letterSpacing: '0.5px',
          mt: props.textPos,
          pl: 2
          // ml: 2,
        },
        '& .MuiInputLabel-root.Mui-focused': {
          // color: '#FFFFFF',  // keep label color white when input is focused
          display: 'none'   // hide the label when input is focused
        },
        '& .MuiOutlinedInput-root:hover + .MuiInputLabel-root': {
          display: 'none',   // hide the label when input is hovered
        },
        '& .MuiInputBase-input': {
          color: '#FFFFFF',  // set input text color to white
          pl: 3
          // ml: 2,
        },
      }} 
    />
    
    </>
    
    
  )
}

export default InputText;
