import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Stack, Grid, useMediaQuery } from "@mui/material";
import Searchbar from "../search/Searchbar";
import Category from "../filter/Category";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ButtonIcon from "../button/ButtonIcon";
import BillRecieve from "../tables/BillRecieve";
import DateFilter from "../filter/DateFilter";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  const bgColor = value === index ? "#F3F1F6" : "transparent";

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, backgroundColor: bgColor }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NavTabsStyled = () => {
  const [value, setValue] = React.useState(0);
  const isMedium = useMediaQuery("(max-width:1100px)");
  const isSmall = useMediaQuery("(max-width:900px)");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [query, setQuery] = useState(null);

  const onFilter = useCallback(
    (category) => {
      setSelectedCategory(category);
    },
    [selectedCategory]
  );

  const onChangeHandlerSearch = useCallback(
    (event) => {
      setQuery(event.target.value);
    },
    [query]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { display: "none" } }} // Hides the default bottom indicator
        >
          <Tab
            label={
              <Stack direction="row" spacing={2}>
                {value === 0 && (
                  <RadioButtonCheckedIcon sx={{ color: "#FF0065" }} />
                )}
                <Typography
                  sx={{
                    textTransform: "none",
                    fontWeight: 500,
                    fontFamily: "Filson Pro Regular",
                  }}
                >
                  Recievable
                </Typography>
              </Stack>
            }
            {...a11yProps(0)}
            sx={{
              "&.Mui-selected": {
                color: "black", // text color for the active tab
                backgroundColor: "#F3F1F6", // gray background for the active tab
                borderRadius: "5px 5px 0 0", // border radius for the active tab'
                textTransform: "none",
              },
            }}
          />
          {/* <Tab
              label={
                <Stack direction="row" spacing={2}>
                  {value === 1 && <RadioButtonCheckedIcon sx={{ color: '#FF0065' }} />}
                  <Typography variant="subtitle1" sx={{ textTransform: 'none', fontWeight: 500, fontFamily: 'Filson Pro Regular' }}>Payable</Typography>
                </Stack>
              }
              {...a11yProps(1)}
              sx={{
                "&.Mui-selected": {
                  color: 'black', // text color for the active tab
                  backgroundColor: 'grey.200', // gray background for the active tab
                  borderRadius: '5px 5px 0 0', // border radius for the active tab'
                  textTransform: 'none'
                }
              }}
            /> */}
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        <Grid container alignItems="center" sx={{ mt: 2 }} spacing={1}>
          <Grid item sm={12} md={isMedium ? 1.5 : 1.2}>
            {isSmall ? (
              <Typography
                variant="h5"
                sx={{
                  color: "#00000",
                  fontFamily: "Alverata Bold Italic",
                  textAlign: "center",
                  mb: 2,
                }}
              >
                Billing
              </Typography>
            ) : (
              <Typography
                variant="h5"
                sx={{ color: "#00000", fontFamily: "Alverata Bold Italic" }}
              >
                Billing
              </Typography>
            )}
          </Grid>

          <Grid item sm={4} md={isMedium ? 3.8 : 3.3}>
            <Searchbar onChangeHandlerSearch={onChangeHandlerSearch} />
          </Grid>

          <Grid item sm={3.5} md={2.5}>
            <Category label="Category" onFilter={onFilter} />
          </Grid>

          <Grid item sm={3.5} md={2.5}>
            <DateFilter label="Date" />
          </Grid>

          <Grid item sm={0.1} md={0.1}>
            <ButtonIcon
              icon={<FileDownloadOutlinedIcon sx={{ color: "#000" }} />}
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: 3,
            px: `${isMedium ? 10 : 12}`,
          }}
        >
          <BillRecieve selectedCategory={selectedCategory} query={query} />
        </Box>
      </CustomTabPanel>

      {/* <CustomTabPanel value={value} index={1}>

          <Grid container alignItems="center" sx={{ mt: 2 }} spacing={1}>

                <Grid item xs={1.1}>
                    <Typography variant="h5" sx={{ color: '#00000', fontFamily: "Alverata Bold Italic" }}>
                        Billing
                    </Typography>
                </Grid>

                <Grid item xs={3}>
                    <Searchbar />
                </Grid>

                <Grid item xs={1.7}>
                      <Box width="650px">
                        <Category label="Category" />
                      </Box>
                </Grid>

                <Grid item xs={1.7}>
                  <Box width="650px">
                    <Category label="Category" />
                  </Box>
                </Grid>

                <Grid item>
                    <ButtonIcon icon={<FileDownloadOutlinedIcon sx={{ color: '#000' }} />}  />
                </Grid>

                <Grid item xs={2.5} sx={{ marginLeft: 'auto' }}>
                  <Stack direction="row"> 
                    <Typography sx={{ mt: 2, fontFamily: 'Filson Pro Medium' }}>Balance: </Typography>
                    <Typography sx={{ mt: 2, ml: 0.5, fontFamily: 'Filson Pro Heavy' }}>$523</Typography>
                    <Button 
                      variant="contained" 
                      sx={{
                        ml: 2,
                        color: '#fff',
                        height: '47px',
                        width: '280px',
                        background: 'transparent linear-gradient(270deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
                        opacity: 1,
                        borderRadius: "32px",
                        textTransform: 'none',
                        fontSize: '20px',
                        fontFamily: 'Filson Pro Medium',
                        "&:hover": {
                            color: '#fff',
                            background: 'transparent linear-gradient(270deg, #2A00FF 0%, #FF0088 100%) 0% 0% no-repeat padding-box',
                            borderRadius: "32px",
                        }
                    }}

                    >
                      Pay
                    </Button>
                  </Stack>
                </Grid>

          </Grid>

          <Box sx={{
            mt: 3,
            px: 12
            }}
          >
            <BillPaid />
          </Box>

        </CustomTabPanel> */}
    </Box>
  );
};

export default NavTabsStyled;
